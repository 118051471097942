import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Paper, Typography, Grid, Tabs, Tab, Alert, useTheme, LinearProgress, Skeleton, Modal, List, ListItem, ListItemIcon, ListItemText, Button, useMediaQuery } from '@mui/material';
import { OverviewProvider, useOverview } from '../../contexts/OverviewContext';
// import DashboardKPIs from '../../components/global/DashboardKPIs';
import ConnectHubSpot from '../../components/sales/OverviewDashboard/ConnectHubSpot';
import WelcomeBack from '../../components/sales/OverviewDashboard/WelcomeBack';
import { useAuth } from '../../contexts/AuthContext';
import { useHubSpot } from '../../contexts/HubSpotContext';
import { useLocation, useNavigate } from 'react-router-dom';
import DataSyncOverview from '../../components/sales/OverviewDashboard/DataSyncOverview';
import { useDashboardStyles } from '../../components/hooks/useDashboardStyles';
import OverallSyncProgress from '../../components/sales/OverviewDashboard/OverallSyncProgress';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useSubscription } from '../../contexts/SubscriptionContext';
import SubscribePopup from '../../components/global/SubscribePopup';
import { formatCurrency } from '../../components/utils/formatters';

// Import Charts
import AIInsightsChart from '../../components/sales/OverviewDashboard/AIInsightsChart';
import AIInsightsDealsFocus from '../../components/sales/OverviewDashboard/AIInsightsDealsFocus';
import RecentlyClosedDeals from '../../components/sales/OverviewDashboard/RecentlyClosedDeals';
import Watchlist from '../../components/sales/OverviewDashboard/Watchlist';

// Mock data
const generateMockDeals = (count) => {
  const stages = ['Qualification', 'Needs Analysis', 'Proposal', 'Negotiation', 'Closed Won', 'Closed Lost'];
  const now = new Date();
  
  return Array.from({ length: count }, (_, i) => ({
    id: `deal${i + 1}`,
    name: `Deal ${i + 1}`,
    amount: Math.floor(Math.random() * 100000) + 5000,
    closeDate: new Date(now.getFullYear(), now.getMonth() + Math.floor(Math.random() * 6), now.getDate() + Math.floor(Math.random() * 30)),
    stage: stages[Math.floor(Math.random() * stages.length)],
    ownerId: `owner${Math.floor(Math.random() * 5) + 1}`,
    createDate: new Date(now.getFullYear(), now.getMonth() - Math.floor(Math.random() * 3), now.getDate() - Math.floor(Math.random() * 90)),
    lastModifiedDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - Math.floor(Math.random() * 30)),
  }));
};

const mockDeals = generateMockDeals(100);

// Calculate derived data
const pipelineSummary = {
  totalDeals: mockDeals.length,
  totalValue: mockDeals.reduce((sum, deal) => sum + deal.amount, 0),
  averageDealSize: mockDeals.reduce((sum, deal) => sum + deal.amount, 0) / mockDeals.length,
};

const dealsByStage = mockDeals.reduce((acc, deal) => {
  acc[deal.stage] = (acc[deal.stage] || 0) + 1;
  return acc;
}, {});

const recentActivity = mockDeals
  .sort((a, b) => b.lastModifiedDate - a.lastModifiedDate)
  .slice(0, 5);

const now = new Date();
const dealsNeedingAttention = mockDeals.filter(deal => 
  (now - deal.lastModifiedDate) / (1000 * 60 * 60 * 24) > 30 || 
  (now - deal.createDate) / (1000 * 60 * 60 * 24) > 90
);

const mockData = {
  deals: mockDeals,
  pipelineSummary,
  dealsByStage,
  recentActivity,
  dealsNeedingAttention,
};

const PlaceholderChart = ({ title }) => {
  const { layout } = useDashboardStyles();
  return (
    <Box sx={layout.chartContainer}>
      <Typography>{title} Placeholder</Typography>
    </Box>
  );
};

const SalesOverview = ({ isPublic }) => {
  const { currentUser } = useAuth();
  const { hubspotConnected } = useHubSpot();
  const { layout, typography } = useDashboardStyles();
  
  // Show limited data for public view
  if (isPublic && !currentUser) {
    return (
      <Box sx={{ ...layout.pageWrapper, pb: 6 }}>
        <Typography variant="h2" sx={typography.pageTitle}>
          Sales Overview
        </Typography>
        
        {/* Show sample/demo data for public view */}
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>
          {/* We can use the mockData we already have defined above */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Example of using mock data */}
            <Box>
              <Typography variant="h6">Pipeline Summary</Typography>
              <Typography>
                Total Deals: {mockData.pipelineSummary.totalDeals}
              </Typography>
              <Typography>
                Total Value: {formatCurrency(mockData.pipelineSummary.totalValue)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <OverviewProvider>
      <SalesOverviewContent />
    </OverviewProvider>
  );
};

const SalesOverviewContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { userData } = useAuth();
  const { isAuthenticated, hubspotConnected } = useHubSpot();
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [showWelcomeBack, setShowWelcomeBack] = useState(false);
  const { layout, typography, components } = useDashboardStyles();
  const { syncProgress, dataFetchDetails, aiInsights, revenueSummary, pipelineHealth, loading: overviewLoading, error: overviewError } = useOverview();
  const { isPro } = useSubscription();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { dataFetchProgress, dataFetchStatus, completedSteps, totalSteps } = useMemo(() => {
    if (overviewLoading || overviewError) {
      return { dataFetchProgress: 0, dataFetchStatus: 'Initializing...', completedSteps: 0, totalSteps: 9 };
    }

    const totalSteps = Object.keys(syncProgress.steps || {}).length;
    const completedSteps = Object.values(syncProgress.steps || {}).filter(step => step.status === 'completed').length;
    const progress = totalSteps > 0 ? Math.round((completedSteps / totalSteps) * 100) : 0;

    let status = 'In progress';
    if (progress === 100) {
      status = 'Completed';
    } else if (Object.values(syncProgress.steps || {}).some(step => step.status === 'error')) {
      status = 'Error occurred';
    }

    return { dataFetchProgress: progress, dataFetchStatus: status, completedSteps, totalSteps };
  }, [syncProgress, overviewLoading, overviewError]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const showWelcome = params.get('welcome') === 'true';
    
    if (showWelcome) {
      setShowWelcomeBack(true);
      // Remove the 'welcome' parameter from the URL
      params.delete('welcome');
      navigate(location.pathname + (params.toString() ? `?${params.toString()}` : ''), { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (location.state?.error) {
      setError(location.state.error);
    }
  }, [location]);

  const handleCloseWelcomeBack = useCallback(() => {
    setShowWelcomeBack(false);
    const params = new URLSearchParams(location.search);
    params.set('welcome', 'complete');
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [location, navigate]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCloseSubscribePopup = () => {
    // This function can be empty or you can add any additional logic here
  };

  const handleNotYet = () => {
    setActiveTab(0); // Switch back to Overview tab
  };

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const showWelcome = params.get('welcome') === 'true' || params.get('welcome') === 'complete';
    
    if (showWelcome && !overviewLoading && !overviewError) {
      setIsDataLoaded(true);
    }
  }, [location, overviewLoading, overviewError]);

  const renderComponentOrSkeleton = (Component, props = {}) => {
    if (Component === DataSyncOverview) {
      return <DataSyncOverview {...props} />;
    }
    if (!isDataLoaded) {
      return (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{ borderRadius: 2 }}
        />
      );
    }
    return <Component {...props} />;
  };
  
  return (
    <Box sx={{ ...layout.pageWrapper, pb: 6 }}>
      {error && <Alert severity="error" sx={components.alert}>{error}</Alert>}
      <Typography variant="h2" sx={typography.pageTitle}>
        Sales Overview
      </Typography>
      
      {showWelcomeBack && <WelcomeBack onClose={handleCloseWelcomeBack} />}
      
      {userData && !userData.hubspotConnected && <ConnectHubSpot />}
      
      <OverallSyncProgress />

      <Tabs 
        value={activeTab} 
        onChange={handleTabChange}
        sx={components.tabs.root}
      >
        <Tab label="Overview" sx={components.tabs.tab} />
        <Tab label="Customize" sx={components.tabs.tab} />
      </Tabs>

      {activeTab === 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'auto', minHeight: 'calc(100vh - 200px)' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: 'auto', minHeight: 'calc(100vh - 200px)' }}>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', mb: 3, flex: 1 }}>
              <Box sx={{ width: isMobile ? '100%' : '50%', display: 'flex', flexDirection: 'column', mr: isMobile ? 0 : 3, mb: isMobile ? 3 : 0 }}>
                <Box sx={{ ...layout.sectionWrapper, flex: 1, mb: 3 }}>
                  <DataSyncOverview />
                </Box>
                <Box sx={{ ...layout.sectionWrapper, flex: 1 }}>
                  <AIInsightsChart />
                </Box>
              </Box>
              <Box sx={{ width: isMobile ? '100%' : '50%', ...layout.sectionWrapper }}>
                <Watchlist />
              </Box>
            </Box>
            <Box sx={{ width: '100%', ...layout.sectionWrapper, flex: isMobile ? 'none' : 1 }}>
              <RecentlyClosedDeals />
            </Box>
          </Box>
        </Box>
      )}

      {activeTab === 1 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 200px)', pb: 4 }}>
          <Box
            sx={{ 
              filter: isPro ? 'none' : 'blur(5px)',
              pointerEvents: isPro ? 'auto' : 'none',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box sx={layout.sectionWrapper}>
                  <Typography variant="h6" sx={typography.sectionTitle}>
                    Custom Stage Mapping
                  </Typography>
                  <Box sx={layout.chartContainer}>
                    <PlaceholderChart title="Custom Stage Mapping" />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={layout.sectionWrapper}>
                  <Typography variant="h6" sx={typography.sectionTitle}>
                    Sales Rep Mapping
                  </Typography>
                  <Box sx={layout.chartContainer}>
                    <PlaceholderChart title="Sales Rep Mapping" />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={layout.sectionWrapper}>
                  <Typography variant="h6" sx={typography.sectionTitle}>
                    Quota Settings
                  </Typography>
                  <Box sx={layout.chartContainer}>
                    <PlaceholderChart title="Quota Settings" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <SubscribePopup
            open={!isPro}
            onClose={handleCloseSubscribePopup}
            onNotYet={handleNotYet}
          />
        </Box>
      )}
    </Box>
  );
};

export default SalesOverview;