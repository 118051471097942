// src/pages/PrivateForecast.jsx v1.0.5
import React, { useEffect } from 'react'
import { Box, Alert, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { 
  FileDownload,
  ShowChart 
} from '@mui/icons-material'
import ForecastDashboard from './ForecastDashboard'
import ConnectHubSpot from '../components/sales/OverviewDashboard/ConnectHubSpot'
import { useAuth } from '../contexts/AuthContext'
import { useForecast } from '../contexts/ForecastContext'
import ErrorBoundary from '../components/common/ErrorBoundary'
import logger from '../utils/frontendLogger'
import SkeletonLoader from '../utils/SkeletonLoader'
import { ForecastProvider } from '../contexts/ForecastContext'
import { exportToPDF } from '../utils/pdfExport'

function PrivateForecast() {
  const { userData, loading: authLoading } = useAuth()
  const { 
    loading: forecastLoading, 
    error: forecastError,
    deals,
    currentMRR,
    currentARR,
    aggregatedData
  } = useForecast()

  const isLoading = authLoading || forecastLoading;
  const hasData = deals?.length > 0 && 
                  aggregatedData !== null && 
                  typeof currentMRR === 'number' && 
                  typeof currentARR === 'number';

  const handleExport = async () => {
    await exportToPDF('forecast-dashboard', 'Revenue_Forecast')
  }

  if (!userData?.hubspotConnected) {
    return <ConnectHubSpot />;
  }

  if (isLoading) {
    return <SkeletonLoader height={600} />;
  }

  if (forecastError) {
    return (
      <Box p={4}>
        <Alert severity="error">
          {forecastError.message}
        </Alert>
      </Box>
    );
  }

  if (!hasData) {
    return (
      <Box p={4}>
        <Alert severity="info">
          Loading forecast data... This may take a few moments.
          {deals?.length === 0 && ' Waiting for deals to load.'}
        </Alert>
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <Box 
        sx={{ 
          py: { xs: 14, md: 14, lg: 20, xl: 20 },
          maxWidth: '1440px',
          margin: '20px', 
          mx: 'auto',
          px: { xs: 2, sm: 3, md: 4 }
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography 
            variant="h2" 
            gutterBottom 
            sx={{ 
              fontSize: { xs: '2rem', md: '3rem' },
              fontWeight: 700,
              mb: 3
            }}
          >
            Your Revenue Forecast Dashboard
          </Typography>
          <Typography 
            variant="h5" 
            sx={{ 
              mb: 4,
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            Access your personalized AI-powered revenue predictions based on your HubSpot data. 
            Generate detailed PDF reports to share forecasts with stakeholders.
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            justifyContent: 'center',
            mb: 6
          }}>
            <Button
              variant="contained"
              onClick={handleExport}
              startIcon={<FileDownload />}
              sx={{
                bgcolor: '#9683EC',
                color: '#fff',
                px: 4,
                py: 1.5,
                minWidth: '180px',
                '&:hover': {
                  bgcolor: '#7b68d9',
                },
              }}
            >
              Export PDF
            </Button>

            <Button
              component={Link}
              to="/sales"
              variant="contained"
              startIcon={<ShowChart />}
              sx={{
                bgcolor: '#33D3B8',
                color: '#fff',
                px: 4,
                py: 1.5,
                minWidth: '180px',
                '&:hover': {
                  bgcolor: '#2bb39d',
                },
              }}
            >
              View Dashboard
            </Button>
          </Box>
        </Box>
        
        <ForecastDashboard />
      </Box>
    </ErrorBoundary>
  );
}

const PrivateForecastWithContext = () => (
  <ForecastProvider>
    <PrivateForecast />
  </ForecastProvider>
);

export default PrivateForecastWithContext