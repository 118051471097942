import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Icon,
  IconButton,
  Tooltip,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  useMediaQuery
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { mint, melon, periwinkle, turquoise } from '../../../theme';

const insightTypeIcons = {
  recentlyClosed: AccessTimeIcon,
  highValue: MonetizationOnIcon,
  closedThisQuarter: AssessmentIcon,
  topPerformer: EmojiEventsIcon,
};

const insightTypeColors = {
  recentlyClosed: mint,
  highValue: melon,
  closedThisQuarter: periwinkle,
  topPerformer: '#1976d2',
};

const StatusCell = ({ status }) => {
  const theme = useTheme();
  const isWon = status === 'Won';
  
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.mode === 'dark'
          ? (isWon ? '#33D3B8' : '#FF8585')
          : (isWon ? '#00B090' : '#FF6666'),
        color: theme.palette.getContrastText(isWon ? '#00B090' : '#FF6666'),
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'inline-block',
        fontWeight: 'bold',
      }}
    >
      {status}
    </Box>
  );
};

const AIRecommendations = ({ insights, onInsightSelect, selectedInsight }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [expandedInsight, setExpandedInsight] = useState(null);

  const generateSparklineData = (deals) => {
    return deals?.map(deal => deal.amount) || [];
  };

  const handleExpandClick = (index) => {
    setExpandedInsight(expandedInsight === index ? null : index);
    if (onInsightSelect) {
      onInsightSelect(insights[index]);
    }
  };

  const renderInsightDetails = (insight) => {
    if (insight.type === 'topPerformer') {
      return (
        <Box sx={{ margin: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Performance Metrics
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2" color="text.secondary">
                  Total Revenue
                </Typography>
                <Typography variant="h6">
                  ${insight.totalRevenue.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" color="text.secondary">
                  Deals Closed
                </Typography>
                <Typography variant="h6">
                  {insight.dealCount}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" color="text.secondary">
                  Win Rate
                </Typography>
                <Typography variant="h6">
                  {(insight.winRate * 100).toFixed(1)}%
                </Typography>
              </Grid>
            </Grid>
          </Box>
          
          {insight.deals?.length > 0 && (
            <TableContainer 
              component={Paper} 
              sx={{ 
                backgroundColor: theme.palette.mode === 'dark' 
                  ? 'rgba(0,0,0,0.1)' 
                  : 'rgba(255,255,255,0.95)',
                boxShadow: 'none',
                borderRadius: '8px',
                border: `1px solid ${theme.palette.divider}`
              }}
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>Deal Name</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 600 }}>Amount</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 600 }}>Close Date</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 600 }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {insight.deals.map((deal, index) => (
                    <TableRow 
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': {
                          backgroundColor: theme.palette.mode === 'dark' 
                            ? 'rgba(255,255,255,0.05)'
                            : 'rgba(0,0,0,0.02)'
                        }
                      }}
                    >
                      <TableCell sx={{ fontSize: '0.875rem' }}>{deal.dealname}</TableCell>
                      <TableCell align="right" sx={{ fontSize: '0.875rem' }}>
                        ${deal.amount.toLocaleString()}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: '0.875rem' }}>
                        {new Date(deal.closedate).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="right">
                        <StatusCell 
                          status={deal.hs_deal_stage_probability === 1 ? 'Won' : 'Closed Lost'} 
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      );
    }

    if (!insight.deals?.length) return null;

    return (
      <Collapse in={true} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 2 }}>
          <TableContainer component={Paper}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Deal Name</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Close Date</TableCell>
                  <TableCell align="right">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {insight.deals.map((deal, index) => (
                  <TableRow key={index}>
                    <TableCell>{deal.dealname}</TableCell>
                    <TableCell align="right">
                      ${deal.amount.toLocaleString()}
                    </TableCell>
                    <TableCell align="right">
                      {new Date(deal.closedate).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">
                      <StatusCell 
                        status={deal.hs_deal_stage_probability === 1 ? 'Won' : 'Closed Lost'} 
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Collapse>
    );
  };

  return (
    <Box sx={{ height: '100%', p: theme.spacing(2) }}>
      <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>
        AI Insights Deals Focus
      </Typography>
      <Grid container spacing={isMobile ? 1 : 2}>
        {insights.map((insight, index) => {
          const IconComponent = insightTypeIcons[insight.type] || AccessTimeIcon;
          const sparklineData = generateSparklineData(insight.deals);
          const isExpanded = expandedInsight === index;
          const insightColor = insightTypeColors[insight.type];

          return (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card 
                onClick={() => handleExpandClick(index)}
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: isMobile ? '160px' : 'auto',
                  backgroundColor: theme.palette.mode === 'dark' 
                    ? theme.palette.background.paper 
                    : '#fff',
                  transition: 'all 0.2s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: theme.shadows[2],
                    transform: 'translateY(-1px)',
                  },
                  borderRadius: '8px'
                }}
              >
                <CardContent sx={{ 
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  padding: theme.spacing(1.5),
                }}>
                  <Box 
                    display="flex" 
                    alignItems="center" 
                    justifyContent="space-between" 
                    mb={1}
                  >
                    <Box display="flex" alignItems="center">
                      <Icon 
                        component={IconComponent} 
                        sx={{ 
                          color: insightColor,
                          mr: 1,
                          fontSize: '1.2rem'
                        }} 
                      />
                      <Typography 
                        variant={isMobile ? "body2" : "body1"} 
                        color={insightColor}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {insight.type.replace(/([A-Z])/g, ' $1').trim()}
                      </Typography>
                    </Box>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleExpandClick(index);
                      }}
                      sx={{ color: insightColor }}
                    >
                      {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>

                  <Typography 
                    variant="body2"
                    sx={{ 
                      mb: 1,
                      flexGrow: 1,
                      lineHeight: 1.4,
                      color: theme.palette.text.secondary
                    }}
                  >
                    {insight.message}
                  </Typography>

                  {sparklineData.length > 0 && (
                    <Tooltip title="Deal value trend">
                      <Box sx={{ mb: 0.5 }}>
                        <Sparklines data={sparklineData} width={100} height={20}>
                          <SparklinesLine 
                            color={insightColor}
                            style={{ strokeWidth: 1, fill: 'none' }}
                          />
                        </Sparklines>
                      </Box>
                    </Tooltip>
                  )}

                  {!isMobile && (
                    <Typography 
                      variant="caption" 
                      color="textSecondary"
                    >
                      {insight.deals ? `${insight.deals.length} deals affected` : 'No deals affected'}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {expandedInsight !== null && (
        renderInsightDetails(insights[expandedInsight])
      )}
    </Box>
  );
};

export default AIRecommendations; 