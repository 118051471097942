// src/pages/PrivateHealthcheck.jsx v1.2.0
import React, { useEffect } from 'react'
import { Box, Alert, Grid, Paper, Typography, Button } from '@mui/material'
import HealthcheckDashboard from './HealthcheckDashboard'
import ConnectHubSpot from '../components/sales/OverviewDashboard/ConnectHubSpot'
import { useAuth } from '../contexts/AuthContext'
import { useHealthCheck } from './ContextProvider'
import ErrorBoundary from '../components/common/ErrorBoundary'
import logger from '../utils/frontendLogger'
import SkeletonLoader from '../utils/SkeletonLoader'
import { 
  FileDownload,
  ShowChart 
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { exportToPDF } from '../utils/pdfExport'

function PrivateHealthcheck() {
  const { userData } = useAuth()
  const { metrics, isLoading, error } = useHealthCheck()

  useEffect(() => {
    logger.debug('PrivateHealthcheck state:', {
      isLoading,
      hasError: !!error,
      hasMetrics: !!metrics,
      isHubSpotConnected: userData?.hubspotConnected
    });
  }, [isLoading, error, metrics, userData]);

  const handleExport = async () => {
    await exportToPDF('healthcheck-dashboard', 'CRM_Health_Report')
  }

  if (isLoading) {
    return <SkeletonLoader height={600} />
  }

  if (error) {
    logger.error('Error loading HealthcheckDashboard', { 
      error,
      userData: {
        hasUser: !!userData,
        hubspotConnected: userData?.hubspotConnected
      }
    });
    return (
      <Box p={4}>
        <Alert severity="error">
          {error.message || 'An error occurred while loading the dashboard'}
        </Alert>
      </Box>
    )
  }

  if (!metrics && userData?.hubspotConnected) {
    logger.warn('No metrics available despite HubSpot being connected');
    return (
      <Box p={4}>
        <Alert severity="warning">
          Loading dashboard data... This may take a few moments.
        </Alert>
      </Box>
    )
  }

  return (
    <ErrorBoundary>
      <Box 
        sx={{ 
          py: { xs: 14, md: 14, lg: 20, xl: 20 },
          maxWidth: '1440px',
          margin: '20px', 
          mx: 'auto',
          px: { xs: 2, sm: 3, md: 4 }
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography 
            variant="h2" 
            gutterBottom 
            sx={{ 
              fontSize: { xs: '2rem', md: '3rem' },
              fontWeight: 700,
              mb: 3
            }}
          >
            Your HubSpot CRM Health Dashboard
          </Typography>
          <Typography 
            variant="h5" 
            sx={{ 
              mb: 4,
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            View your personalized CRM health analysis using real-time HubSpot data. 
            Download and share insights with your team using our PDF export feature.
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            justifyContent: 'center',
            mb: 6
          }}>
            <Button
              variant="contained"
              onClick={handleExport}
              startIcon={<FileDownload />}
              sx={{
                bgcolor: '#9683EC',
                color: '#fff',
                px: 4,
                py: 1.5,
                minWidth: '180px',
                '&:hover': {
                  bgcolor: '#7b68d9',
                },
              }}
            >
              Export PDF
            </Button>

            <Button
              component={Link}
              to="/sales"
              variant="contained"
              startIcon={<ShowChart />}
              sx={{
                bgcolor: '#33D3B8',
                color: '#fff',
                px: 4,
                py: 1.5,
                minWidth: '180px',
                '&:hover': {
                  bgcolor: '#2bb39d',
                },
              }}
            >
              View Dashboard
            </Button>
          </Box>
        </Box>
        
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                overflow: 'hidden',
                borderRadius: 2,
                height: '100%',
                maxWidth: '1200px',
                mx: 'auto'
              }}
            >
              {!userData?.hubspotConnected && <ConnectHubSpot />}
              {userData?.hubspotConnected && <HealthcheckDashboard />}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </ErrorBoundary>
  )
}

export default PrivateHealthcheck