// src/utils/dealsDataProcessing.js v1.0.0

import {
  eachMonthOfInterval,
  startOfYear,
  endOfYear,
  format,
  parseISO
} from 'date-fns';

// Helper function to safely filter deals
const safeFilterDeals = (deals, filterFn) => {
  return Array.isArray(deals) ? deals.filter(filterFn) : [];
};

// Helper function to filter open deals
const filterOpenDeals = (deals) => safeFilterDeals(deals, deal => deal.isOpen === true);

export const processDealValueOverTime = (deals, currentYear, previousYear) => {
  // Note: This function processes ALL deals, both open and closed
  console.log('Processing all deals:', deals);
  if (!deals || deals.length === 0) return [];

  const monthlyData = {
    [currentYear]: {},
    [previousYear]: {}
  };

  // Initialize all months with 0
  const months = eachMonthOfInterval({
    start: startOfYear(new Date(previousYear, 0, 1)),
    end: endOfYear(new Date(currentYear, 0, 1))
  });

  months.forEach(month => {
    const year = month.getFullYear();
    const monthKey = format(month, 'MMM');
    if (year === currentYear || year === previousYear) {
      monthlyData[year][monthKey] = 0;
    }
  });

  // Aggregate deal values by month and year
  deals.forEach(deal => {
    const closeDate = parseISO(deal.closedate);
    const year = closeDate.getFullYear();
    const month = format(closeDate, 'MMM');
    const amount = parseFloat(deal.amount) || 0;

    if (year === currentYear || year === previousYear) {
      monthlyData[year][month] = (monthlyData[year][month] || 0) + amount;
    }
  });

  // Convert to Nivo format
  return [
    {
      id: `${currentYear} Deal Value`,
      data: Object.entries(monthlyData[currentYear]).map(([month, value]) => ({ x: month, y: value })),
    },
    {
      id: `${previousYear} Deal Value`,
      data: Object.entries(monthlyData[previousYear]).map(([month, value]) => ({ x: month, y: value })),
    },
  ];
};



export const processDealSizeScatterPlot = (deals) => {
  console.log('Deals received:', deals);
  if (!deals || deals.length === 0) return [];
  const openDeals = filterOpenDeals(deals);
  console.log('Open deals:', openDeals);
  const processedData = openDeals.map(deal => {
    console.log('Processing deal:', deal);
    return {
      x: deal.daysOpen || 0,
      y: parseFloat(deal.amount) || 0,
      probability: parseFloat(deal.hs_deal_stage_probability) || 0,
      dealName: deal.dealname || 'Unnamed Deal',
    };
  });
  console.log('Processed data:', processedData);
  return [{ id: 'Open Deals', data: processedData }];
};

// New function for WinProbabilityHeatmap
export const processWinProbabilityHeatmap = (deals) => {
  const stages = ['Qualification', 'Meeting', 'Proposal', 'Negotiation'];
  const sizes = ['Small', 'Medium', 'Large', 'Enterprise'];

  // Initialize the heatmap data structure
  const heatmapData = stages.map(stage => ({
    id: stage,
    data: sizes.map(size => ({ x: size, y: 0 }))
  }));

  // Group deals by pipeline stage and deal size
  const groupedDeals = deals.reduce((acc, deal) => {
    const stage = deal.pipelineStage || 'Unknown';
    const sizeCategory = getDealSizeCategory(deal.amount);
    if (!acc[stage]) acc[stage] = {};
    if (!acc[stage][sizeCategory]) acc[stage][sizeCategory] = [];
    acc[stage][sizeCategory].push(deal);
    return acc;
  }, {});

  // Calculate win probabilities and update heatmapData
  stages.forEach((stage, stageIndex) => {
    sizes.forEach((size, sizeIndex) => {
      const dealsInCategory = groupedDeals[stage]?.[size] || [];
      const winProbability = calculateWinProbability(dealsInCategory);
      heatmapData[stageIndex].data[sizeIndex].y = winProbability;
    });
  });

  console.log('Heatmap data:', heatmapData);
  return heatmapData;
};

// Helper function to categorize deal size
const getDealSizeCategory = (amount) => {
  if (amount < 10000) return 'Small';
  if (amount < 50000) return 'Medium';
  if (amount < 100000) return 'Large';
  return 'Enterprise';
};

// Helper function to calculate win probability
const calculateWinProbability = (deals) => {
  const totalDeals = deals.length;
  if (totalDeals === 0) return 0;
  const wonDeals = deals.filter(deal => deal.status === 'won').length;
  return Math.round((wonDeals / totalDeals) * 100);
};

export const processPipelineFunnel = (deals) => {
  if (!deals || deals.length === 0) return [];
  const stageData = deals.reduce((acc, deal) => {
    const stage = deal.dealstage || 'Unknown';
    if (!acc[stage]) {
      acc[stage] = { value: 0, dealCount: 0 };
    }
    acc[stage].value += parseFloat(deal.amount) || 0;
    acc[stage].dealCount += 1;
    return acc;
  }, {});

  return Object.entries(stageData)
    .map(([stage, data]) => ({
      id: stage,
      label: stage,
      value: data.value,
      dealCount: data.dealCount,
    }))
    .sort((a, b) => b.value - a.value);
};

// Ensure that processDealTypeDistribution and processTopDeals are filtering for open deals
export const processDealTypeDistribution = (deals) => {
  if (!deals || deals.length === 0) return [];
  const openDeals = filterOpenDeals(deals);
  const typeDistribution = openDeals.reduce((acc, deal) => {
    const dealType = deal.dealstage || 'Unknown';
    acc[dealType] = (acc[dealType] || 0) + parseFloat(deal.amount) || 0;
    return acc;
  }, {});

  const total = Object.values(typeDistribution).reduce((sum, value) => sum + value, 0);

  return Object.entries(typeDistribution).map(([type, value]) => ({
    id: type,
    label: type,
    value,
    percentage: (value / total) * 100,
  }));
};

export const processTopDeals = (deals, limit = 10) => {
  if (!deals || deals.length === 0) return [];
  const openDeals = deals.filter(deal => deal.isOpen === true);
  
  // Calculate total amount of all open deals
  const totalAmount = openDeals.reduce((sum, deal) => sum + (parseFloat(deal.amount) || 0), 0);

  console.log('Total pipeline amount:', totalAmount); // Debugging log

  return openDeals
    .sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount))
    .slice(0, limit)
    .map(deal => {
      const amount = parseFloat(deal.amount) || 0;
      let percentOfPipe = totalAmount > 0 ? (amount / totalAmount) * 1 : 0;
      percentOfPipe = Math.min(percentOfPipe, 100); // Ensure it doesn't exceed 100%

      console.log(`Deal: ${deal.dealname}, Amount: ${amount}, Percent: ${percentOfPipe.toFixed(2)}%`); // Debugging log

      return {
        id: deal.id,
        name: deal.dealname,
        amount: amount,
        probability: parseFloat(deal.hs_deal_stage_probability) || 0,
        closeDate: deal.closedate,
        stage: deal.dealstage,
        daysOpen: deal.daysOpen || 0,
        owner: deal.hubspot_owner_id,
        pipeline: deal.pipeline,
        lastModified: deal.hs_lastmodifieddate,
        nextStep: deal.hs_next_step,
        percentOfPipe: percentOfPipe,
        createDate: deal.createdate,
        lastContacted: deal.notes_last_contacted,
        needsAttention: deal.needsAttention > 0,
      };
    });
};

// Helper function to get required fields
const getRequiredFields = () => [
  'amount',
  'closedate',
  'dealname',
  'dealstage',
  'hubspot_owner_id',
  'pipeline'
];

export const calculateDataCompleteness = (deals) => {
  const openDeals = deals.filter(deal => deal.isOpen === true);
  
  if (openDeals.length === 0) return 0;
  
  const totalFields = openDeals.length * 10; // Assuming 10 fields per deal (6 required + 4 new)
  let filledFields = 0;

  openDeals.forEach(deal => {
    const requiredFields = ['amount', 'closedate', 'dealname', 'dealstage', 'hubspot_owner_id', 'pipeline'];
    requiredFields.forEach(field => {
      if (deal[field] !== undefined && deal[field] !== null && deal[field] !== '') {
        filledFields++;
      }
    });

    // Check the new properties
    if (!deal.pastDue) filledFields++;
    if (!deal.missingInformation) filledFields++;
    if (!deal.missingOwner) filledFields++;
    if (!deal.missingAmount) filledFields++;
  });

  return filledFields / totalFields;
};

export const processDealAgeDistribution = (deals) => {
  if (!Array.isArray(deals) || deals.length === 0) return [];

  const ageRanges = [
    { label: '0-30 days', min: 0, max: 30, count: 0 },
    { label: '31-60 days', min: 31, max: 60, count: 0 },
    { label: '61-90 days', min: 61, max: 90, count: 0 },
    { label: '90+ days', min: 91, max: Infinity, count: 0 }
  ];

  deals.forEach(deal => {
    const age = deal.daysOpen || 0;
    const range = ageRanges.find(r => age >= r.min && age <= r.max);
    if (range) range.count++;
  });

  return ageRanges;
};

export const processMissingInformation = (deals) => {
  if (!Array.isArray(deals) || deals.length === 0) return [];

  const requiredFields = getRequiredFields();
  const missingInfo = requiredFields.map(field => ({ field, missingCount: 0 }));

  deals.forEach(deal => {
    requiredFields.forEach((field, index) => {
      if (deal[field] === undefined || deal[field] === null || deal[field] === '') {
        missingInfo[index].missingCount++;
      }
    });
  });

  return missingInfo;
};

export const processDealAttentionNeeds = (deals) => {
  const openDeals = deals.filter(deal => deal.isOpen === true);

  const categories = {
    pastDue: { name: 'Past Due', value: 0 },
    missingInformation: { name: 'Missing Information', value: 0 },
    missingOwner: { name: 'Missing Owner', value: 0 },
    missingAmount: { name: 'Missing Amount', value: 0 },
    noRecentActivity: { name: 'No Recent Activity', value: 0 },
    stagnant: { name: 'Stagnant', value: 0 },
    highValue: { name: 'High Value at Risk', value: 0 },
    closingSoon: { name: 'Closing Soon', value: 0 }
  };

  openDeals.forEach(deal => {
    if (deal.pastDue) categories.pastDue.value++;
    if (deal.missingInformation) categories.missingInformation.value++;
    if (deal.missingOwner) categories.missingOwner.value++;
    if (deal.missingAmount) categories.missingAmount.value++;
    if (noRecentActivity(deal)) categories.noRecentActivity.value++;
    if (isStagnant(deal)) categories.stagnant.value++;
    if (isHighValueAtRisk(deal)) categories.highValue.value++;
    if (isClosingSoon(deal)) categories.closingSoon.value++;
  });

  return Object.values(categories).filter(category => category.value > 0);
};

// Helper functions (implement these based on your business logic)
const noRecentActivity = (deal) => {
  const lastActivityDate = new Date(deal.notes_last_updated || deal.notes_last_contacted || deal.updatedAt);
  const daysSinceLastActivity = (new Date() - lastActivityDate) / (1000 * 60 * 60 * 24);
  return daysSinceLastActivity > 30; // No activity in the last 30 days
};

const isStagnant = (deal) => {
  return deal.daysOpen > 90; // Deal has been open for more than 90 days
};

const isHighValueAtRisk = (deal) => {
  const amount = parseFloat(deal.amount);
  const probability = parseFloat(deal.hs_deal_stage_probability);
  return amount > 100000 && probability < 50; // High value deals with low probability
};

const isClosingSoon = (deal) => {
  const closeDate = new Date(deal.closedate);
  const daysUntilClose = (closeDate - new Date()) / (1000 * 60 * 60 * 24);
  return daysUntilClose <= 30 && daysUntilClose > 0; // Closing within the next 30 days
};

// New function for Deal Stage Transition Times
export const processDealStageTransitionTimes = (deals, pipelineStages, getStageName) => {
  console.log('Processing deal stage transition times');
  console.log('Deals:', deals);
  console.log('Pipeline Stages:', pipelineStages);
  console.log('GetStageName function:', getStageName);

  if (!deals || !Array.isArray(deals) || deals.length === 0) {
    console.error('Invalid or empty deals array');
    return [];
  }

  if (!pipelineStages || !Array.isArray(pipelineStages) || pipelineStages.length === 0) {
    console.error('Invalid or empty pipelineStages array');
    return [];
  }

  if (typeof getStageName !== 'function') {
    console.error('getStageName is not a function');
    return [];
  }

  const stageTimes = pipelineStages.reduce((acc, stage) => {
    if (stage && stage.id) {
      acc[stage.id] = [];
    }
    return acc;
  }, {});

  deals.forEach(deal => {
    pipelineStages.forEach(stage => {
      if (stage && stage.id) {
        const timeInStage = parseFloat(deal[`hs_time_in_${stage.id}`]) || 0;
        if (timeInStage > 0) {
          stageTimes[stage.id].push(timeInStage);
        }
      }
    });
  });

  const result = pipelineStages
    .map(stage => ({
      stage: getStageName(stage.id),
      averageTime: stageTimes[stage.id].length > 0 
        ? stageTimes[stage.id].reduce((sum, time) => sum + time, 0) / stageTimes[stage.id].length / 86400 
        : 0 // Convert seconds to days
    }))
    .filter(stageData => stageData.averageTime > 0); // Only include stages with data

  console.log('Processed result:', result);
  return result;
};

// New function for Forecast Accuracy Scatter Plot
export const processForecastAccuracyScatterPlot = (deals) => {
  const processedData = deals.map(deal => {
    const forecastedAmount = parseFloat(deal.hs_forecast_amount) || 0;
    const actualAmount = parseFloat(deal.amount) || 0;
    const accuracy = forecastedAmount > 0 ? Math.min(actualAmount / forecastedAmount, 2) : 0;

    return {
      x: forecastedAmount,
      y: actualAmount,
      dealName: deal.dealname || 'Unnamed Deal',
      accuracy: accuracy > 1 ? 2 - accuracy : accuracy, // Normalize accuracy to 0-1 range
    };
  });

  return [{ id: 'Forecast Accuracy', data: processedData }];
};

export const processDataQualityScoreByRep = (deals, owners) => {
  if (!Array.isArray(deals) || !Array.isArray(owners)) {
    console.error('Invalid input: deals or owners is not an array');
    return [];
  }

  const openDeals = deals.filter(deal => deal.isOpen === true);

  const repScores = {};

  // Initialize scores for all owners
  owners.forEach(owner => {
    if (owner && owner.id) {
      repScores[owner.id] = { totalScore: 0, dealCount: 0 };
    }
  });

  // Calculate scores for open deals
  openDeals.forEach(deal => {
    const repId = deal.hubspot_owner_id;
    if (repId && repScores[repId]) {
      const dealScore = calculateDealQualityScore(deal);
      repScores[repId].totalScore += dealScore;
      repScores[repId].dealCount += 1;
    }
  });

  // Calculate average scores and prepare data for chart
  return Object.entries(repScores)
    .map(([repId, { totalScore, dealCount }]) => {
      const owner = owners.find(o => o.id === repId);
      const averageScore = dealCount > 0 ? totalScore / dealCount : 0;
      return {
        rep: owner ? `${owner.firstName} ${owner.lastName}` : 'Unknown',
        score: averageScore
      };
    })
    .filter(item => item.score > 0) // Only include reps with scores
    .sort((a, b) => b.score - a.score); // Sort by score descending
};

// Helper function to calculate the quality score for a single deal
const calculateDealQualityScore = (deal) => {
  const requiredFields = [
    'amount', 'closedate', 'dealname', 'dealstage', 
    'hubspot_owner_id', 'pipeline'
  ];
  const optionalFields = [
    'hs_next_step', 'notes_last_contacted', 'notes_last_updated', 
    'notes_next_activity_date'
  ];

  let score = 0;
  let totalFields = requiredFields.length + optionalFields.length + 4; // Adding 4 for the new fields

  requiredFields.forEach(field => {
    if (deal[field] !== undefined && deal[field] !== null && deal[field] !== '') {
      score += 1;
    }
  });

  optionalFields.forEach(field => {
    if (deal[field] !== undefined && deal[field] !== null && deal[field] !== '') {
      score += 0.5;
    }
  });

  // Add checks for the new properties
  if (!deal.pastDue) score += 1;
  if (!deal.missingInformation) score += 1;
  if (!deal.missingOwner) score += 1;
  if (!deal.missingAmount) score += 1;

  return score / totalFields;
};

// Add more data processing functions for other charts here