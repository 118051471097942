import React from 'react';
import { Box, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { useHealthCheck } from '../../../contexts/HealthcheckContextProvider';
import { useTheme } from '@mui/material/styles';
import { turquoise, mint, melon, periwinkle } from '../../../theme';

const ActivityTrendsChart = () => {
  const { metrics, isLoading } = useHealthCheck();
  const theme = useTheme();

  if (isLoading || !metrics?.activityTrends) return null;

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload) return null;

    return (
      <Box sx={{
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        p: 1.5,
        boxShadow: theme.shadows[3],
      }}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          {label}
        </Typography>
        {payload.map((entry) => (
          <Box 
            key={entry.name}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: 0.5,
            }}
          >
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: entry.color,
              }}
            />
            <Typography variant="body2">
              {`${entry.name}: ${entry.value}`}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart 
          data={metrics.activityTrends}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke={theme.palette.divider}
          />
          <XAxis 
            dataKey="month" 
            stroke={theme.palette.text.secondary}
            tick={{ fill: theme.palette.text.secondary }}
          />
          <YAxis 
            stroke={theme.palette.text.secondary}
            tick={{ fill: theme.palette.text.secondary }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend 
            wrapperStyle={{
              paddingTop: '20px'
            }}
          />
          <Bar 
            dataKey="deals" 
            fill={theme.palette.mode === 'dark' ? turquoise : mint}
            radius={[4, 4, 0, 0]}
            name="Deals" 
          />
          <Bar 
            dataKey="contacts" 
            fill={melon}
            radius={[4, 4, 0, 0]}
            name="Contacts" 
          />
          <Bar 
            dataKey="score" 
            fill={periwinkle}
            radius={[4, 4, 0, 0]}
            name="Activity Score" 
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ActivityTrendsChart; 