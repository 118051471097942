import React from 'react';
import { Box, Typography, Grid, LinearProgress } from '@mui/material';
import { useHealthCheck } from '../../../contexts/HealthcheckContextProvider';
import { useTheme } from '@mui/material/styles';
import { melon, turquoise, mint } from '../../../theme';

const QualityMetric = ({ label, value, barColor }) => {
  const theme = useTheme();
  
  return (
    <Box sx={{ 
      mb: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start'
    }}>
      <Typography 
        variant="body2" 
        color="text.secondary" 
        gutterBottom
        sx={{ 
          fontWeight: 'medium',
          mb: 1.5
        }}
      >
        {label}
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 2 
      }}>
        <Box sx={{ flexGrow: 1 }}>
          <LinearProgress 
            variant="determinate" 
            value={value} 
            sx={{ 
              height: 12,
              borderRadius: 6,
              backgroundColor: theme.palette.mode === 'dark' 
                ? theme.palette.grey[800] 
                : theme.palette.grey[200],
              '& .MuiLinearProgress-bar': {
                borderRadius: 6,
                backgroundColor: barColor,
              }
            }}
          />
        </Box>
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ 
            minWidth: '45px',
            textAlign: 'right',
            fontWeight: 'medium'
          }}
        >
          {value}%
        </Typography>
      </Box>
    </Box>
  );
};

const DataQualityOverview = () => {
  const { metrics, isLoading } = useHealthCheck();
  const theme = useTheme();

  if (isLoading || !metrics?.dataHealth) return null;

  const { deals, contacts } = metrics.dataHealth;

  return (
    <Box sx={{ 
      height: 350,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Grid container spacing={4} sx={{ height: '100%' }}>
        {/* Deals Data Quality */}
        <Grid item xs={12} md={6} sx={{ 
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant="subtitle2" sx={{ 
            mb: 2,
            color: theme.palette.text.primary,
            fontWeight: 'medium'
          }}>
            Deals Data Quality
          </Typography>
          <Box sx={{ flex: 1 }}>
            {Object.entries(deals.criticalFields).map(([field, value]) => (
              <QualityMetric
                key={field}
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                value={value}
                barColor={melon}
              />
            ))}
          </Box>
        </Grid>

        {/* Contacts Data Quality */}
        <Grid item xs={12} md={6} sx={{ 
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant="subtitle2" sx={{ 
            mb: 2,
            color: theme.palette.text.primary,
            fontWeight: 'medium'
          }}>
            Contacts Data Quality
          </Typography>
          <Box sx={{ flex: 1 }}>
            {Object.entries(contacts.criticalFields).map(([field, value]) => (
              <QualityMetric
                key={field}
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                value={value}
                barColor={theme.palette.mode === 'dark' ? turquoise : mint}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataQualityOverview; 