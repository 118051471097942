import React from 'react';
import { 
  Box, 
  Button,
  Typography, 
  Paper,
  Grid, 
  LinearProgress,
  Stack,
  Alert
} from '@mui/material';
import {
  Warning as WarningIcon,
  CheckCircle,
  FileDownload
} from '@mui/icons-material';
import HealthMetricsSummary from '../../components/sales/HealthcheckDashboard/HealthMetricsSummary';
import DataQualityOverview from '../../components/sales/HealthcheckDashboard/DataQualityOverview';
import ActivityTrendsChart from '../../components/sales/HealthcheckDashboard/ActivityTrendsChart';
import OwnerPipelineTable from '../../components/sales/HealthcheckDashboard/OwnerPipelineTable';
import { HealthCheckProvider, useHealthCheck } from '../../contexts/HealthcheckContextProvider';
import { useAuth } from '../../contexts/AuthContext';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useDashboardTheme } from '../../hooks/useDashboardTheme';
import ErrorDisplay from '../../utils/ErrorDisplay';
import { Link } from 'react-router-dom';
import { exportToPDF } from '../../utils/pdfExport';
import { useDashboardStyles } from '../../components/hooks/useDashboardStyles';

// Create a separate component for the dashboard content
const DashboardContent = () => {
  const { currentUser } = useAuth();
  const { metrics, isLoading, error, rawData } = useHealthCheck();
  const { colors: dashboardColors, isDark } = useDashboardTheme();
  const { layout, typography } = useDashboardStyles();
  const { deals, contacts, engagements } = rawData || {};

  // Early returns for error states
  if (!currentUser) {
    return <ErrorDisplay 
      error={new Error('Authentication required')} 
      details="Please log in to view the health dashboard."
    />;
  }

  if (isLoading) {
    return <Box sx={layout.pageWrapper}>
      <LinearProgress />
    </Box>;
  }

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (!metrics) {
    return <ErrorDisplay 
      error={new Error('No data available')} 
      details="Please ensure your data is properly synced."
    />;
  }

  return (
    <Box sx={layout.pageWrapper}>
      <Typography variant="h2" sx={typography.pageTitle}>
        Sales Health Overview
      </Typography>
      
      <Grid container spacing={3}>
        {/* Header with Export Button */}
        <Grid item xs={12}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 2 
          }}>
            <Button
              variant="outlined"
              onClick={() => exportToPDF('health-dashboard', 'Sales_Health_Overview')}
              startIcon={<FileDownload />}
            >
              Export PDF
            </Button>
          </Box>
        </Grid>

        {/* Health Metrics Summary */}
        <Grid item xs={12}>
          <Box sx={layout.sectionWrapper}>
            <Typography variant="h6" sx={typography.sectionTitle}>
              Health Metrics Summary
            </Typography>
            <HealthMetricsSummary metrics={metrics} dashboardColors={dashboardColors} />
          </Box>
        </Grid>

        {/* Data Quality Overview */}
        <Grid item xs={12} md={6}>
          <Box sx={layout.sectionWrapper}>
            <Typography variant="h6" sx={typography.sectionTitle}>
              Data Quality Overview
            </Typography>
            <DataQualityOverview metrics={metrics} dashboardColors={dashboardColors} />
          </Box>
        </Grid>

        {/* Activity Trends */}
        <Grid item xs={12} md={6}>
          <Box sx={layout.sectionWrapper}>
            <Typography variant="h6" sx={typography.sectionTitle}>
              Activity Trends
            </Typography>
            <ActivityTrendsChart metrics={metrics} dashboardColors={dashboardColors} />
          </Box>
        </Grid>

        {/* Owner Pipeline Table */}
        <Grid item xs={12}>
          <Box sx={layout.sectionWrapper}>
            <Typography variant="h6" sx={typography.sectionTitle}>
              Owner Pipeline Overview
            </Typography>
            <OwnerPipelineTable dashboardColors={dashboardColors} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

// Wrap the dashboard content with HealthCheckProvider
const HealthcheckDashboard = () => {
  return (
    <HealthCheckProvider>
      <DashboardContent />
    </HealthCheckProvider>
  );
};

export default HealthcheckDashboard;
