import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';

const HealthCheckContext = createContext(undefined);

// Move DEMO_METRICS before the component
const DEMO_METRICS = {
  accountAge: {
    totalDeals: 156,
    totalContacts: 892,
  },
  dataHealth: {
    deals: {
      percentComplete: 49,
      criticalFields: {
        amount: 65,
        stage: 82,
        owner: 91,
        closeDate: 73
      }
    },
    contacts: {
      criticalFields: {
        email: 95,
        phone: 45,
        company: 36,
        title: 52
      }
    }
  },
  activityTrends: [
    { month: 'Jun', deals: 12, contacts: 45, score: 65 },
    { month: 'Jul', deals: 15, contacts: 52, score: 72 },
    { month: 'Aug', deals: 8, contacts: 38, score: 48 },
    { month: 'Sep', deals: 6, contacts: 25, score: 35 },
    { month: 'Oct', deals: 4, contacts: 18, score: 28 },
    { month: 'Nov', deals: 3, contacts: 15, score: 22 }
  ],
  emailDeliverability: {
    rate: 97,
    bounceRate: 2,
  },
  duplicateRate: 3.5,
}

// Add new helper functions after existing ones
function calculateEmailMetrics(engagements) {
  // Only consider emails from the last 30 days
  const thirtyDaysAgo = Date.now() - (30 * 24 * 60 * 60 * 1000);
  
  const emailEngagements = engagements.filter(e => 
    e.type === 'email' && 
    e.emailStatus &&
    e.timestamp >= thirtyDaysAgo
  );
  
  const total = emailEngagements.length;
  if (total === 0) return { rate: 0, bounceRate: 0, spamRate: 0 };
  
  const delivered = emailEngagements.filter(e => 
    e.emailStatus.toUpperCase() === 'DELIVERED'
  ).length;
  
  const bounced = emailEngagements.filter(e => 
    ['BOUNCED', 'HARD_BOUNCE', 'SOFT_BOUNCE'].includes(e.emailStatus.toUpperCase())
  ).length;
  
  const spam = emailEngagements.filter(e => 
    ['SPAM', 'BLOCKED'].includes(e.emailStatus.toUpperCase())
  ).length;

  return {
    rate: Math.round((delivered / total) * 100),
    bounceRate: Math.round((bounced / total) * 100),
    spamRate: Math.round((spam / total) * 100)
  };
}

function calculateDuplicateRate(contacts) {
  if (!contacts.length) return 0;
  
  const emailGroups = contacts.reduce((acc, contact) => {
    const email = contact.properties?.email?.toLowerCase();
    if (!email) return acc;
    acc[email] = (acc[email] || 0) + 1;
    return acc;
  }, {});

  const duplicates = Object.values(emailGroups)
    .filter(count => count > 1)
    .reduce((sum, count) => sum + count - 1, 0);

  return (duplicates / contacts.length) * 100;
}

// Add new helper functions for field completeness
function calculateCriticalFields(items, fields, type = 'deals') {
  const result = {};
  fields.forEach(field => {
    const total = items.length;
    if (total === 0) {
      result[field] = 0;
      return;
    }

    const complete = items.filter(item => {
      if (type === 'deals') {
        if (field === 'amount') return !item.missingAmount;
        if (field === 'stage') return item.dealstage;
        if (field === 'owner') return !item.missingOwner;
        if (field === 'closeDate') return !item.missingCloseDate;
      } else { // contacts
        if (field === 'email') return Boolean(item.properties?.email);
        if (field === 'phone') return Boolean(item.properties?.phone);
        if (field === 'company') return Boolean(item.properties?.company);
        if (field === 'jobtitle') return Boolean(item.properties?.jobtitle);
      }
      return false;
    }).length;

    result[field] = Math.round((complete / total) * 100);
  });
  return result;
}

// Add this calculation function after the other helper functions
function calculateOwnerMetrics(deals, owners) {
  return Object.values(owners)
    .map(owner => {
      const ownerDeals = deals.filter(deal => deal.hubspot_owner_id === owner.id);
      
      if (ownerDeals.length === 0) return null;

      // Calculate quarterly metrics
      const currentDate = new Date();
      const quarterStart = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3, 1);
      const quarterEnd = new Date(quarterStart);
      quarterEnd.setMonth(quarterStart.getMonth() + 3);

      // Keep all existing health indicator calculations
      const healthMetrics = ownerDeals.reduce((metrics, deal) => {
        const lastActivity = new Date(deal.lastactivitydate || 0);
        const isOutdated = Date.now() - lastActivity.getTime() > 30 * 24 * 60 * 60 * 1000;

        const createDate = new Date(deal.createdate || 0);
        const dealAge = (Date.now() - createDate.getTime()) / (24 * 60 * 60 * 1000);

        const closeDate = new Date(deal.closedate || 0);
        const closingSoon = closeDate > Date.now() && 
                          closeDate < Date.now() + (30 * 24 * 60 * 60 * 1000);

        return {
          totalDeals: metrics.totalDeals + 1,
          outdatedDeals: metrics.outdatedDeals + (isOutdated ? 1 : 0),
          totalAge: metrics.totalAge + dealAge,
          totalVelocity: metrics.totalVelocity + (deal.dealVelocity || 0),
          totalWinProb: metrics.totalWinProb + (deal.dealWinProbability || 0),
          totalAccuracy: metrics.totalAccuracy + (deal.forecastAccuracy || 0),
          needsAttentionDeals: metrics.needsAttentionDeals + (deal.needsAttention ? 1 : 0),
          dealsClosingSoon: metrics.dealsClosingSoon + (closingSoon ? 1 : 0),
          totalValue: metrics.totalValue + (Number(deal.amount) || 0)
        };
      }, {
        totalDeals: 0,
        outdatedDeals: 0,
        totalAge: 0,
        totalVelocity: 0,
        totalWinProb: 0,
        totalAccuracy: 0,
        needsAttentionDeals: 0,
        dealsClosingSoon: 0,
        totalValue: 0
      });

      // Calculate existing health scores
      const avgDealAge = healthMetrics.totalAge / healthMetrics.totalDeals;
      const avgWinProbability = healthMetrics.totalWinProb / healthMetrics.totalDeals;
      const dealVelocity = healthMetrics.totalVelocity / healthMetrics.totalDeals;
      const forecastAccuracy = healthMetrics.totalAccuracy / healthMetrics.totalDeals;

      // Calculate engagement and risk scores
      const engagementScore = calculateEngagementScore(ownerDeals);
      const riskScore = calculateRiskScore(ownerDeals);

      // Calculate health score
      const healthScore = Math.round(
        (engagementScore * 0.4) + 
        ((100 - riskScore) * 0.4) + 
        (avgWinProbability * 100 * 0.2)
      );

      // Add the detailed metrics calculation
      const detailedMetrics = {
        dealsCreatedThisQuarter: ownerDeals.filter(deal => {
          const createDate = new Date(deal.createdate);
          return createDate >= quarterStart && createDate < quarterEnd;
        }).length,
        dealsClosingThisQuarter: ownerDeals.filter(deal => {
          const closeDate = new Date(deal.closedate);
          return closeDate >= quarterStart && closeDate < quarterEnd;
        }).length,
        avgNumAssociatedContacts: ownerDeals.reduce((sum, deal) => 
          sum + (deal.num_associated_contacts || 0), 0) / ownerDeals.length || 0,
        avgNotesPerDeal: ownerDeals.reduce((sum, deal) => 
          sum + (deal.num_notes || 0), 0) / ownerDeals.length || 0,
        avgLeadConversionRate: ownerDeals.filter(deal => 
          deal.dealstage === 'closedwon').length / ownerDeals.length || 0,
        totalForecastAmount: ownerDeals.reduce((sum, deal) => 
          sum + (deal.forecast_amount || 0), 0),
        totalProjectedAmount: ownerDeals.reduce((sum, deal) => 
          sum + (deal.projected_amount || 0), 0),
        totalMRR: ownerDeals.reduce((sum, deal) => 
          sum + (deal.mrr || 0), 0),
        avgTimeSinceLastContacted: ownerDeals.reduce((sum, deal) => {
          const lastContact = new Date(deal.notes_last_contacted || 0);
          return sum + (Date.now() - lastContact.getTime()) / (1000 * 60 * 60 * 24);
        }, 0) / ownerDeals.length || 0,
        dealsWithNoNextActivity: ownerDeals.filter(deal => 
          !deal.next_activity_date).length,
        avgTimeInDealStage: ownerDeals.reduce((sum, deal) => 
          sum + (deal.time_in_stage || 0), 0) / ownerDeals.length || 0
      };

      // Return combined metrics
      return {
        id: owner.id,
        owner: `${owner.firstName || ''} ${owner.lastName || ''}`.trim() || 'Unknown Owner',
        totalDeals: healthMetrics.totalDeals,
        totalValue: healthMetrics.totalValue,
        outdatedDeals: healthMetrics.outdatedDeals,
        avgDealAge,
        isAgeTrending: avgDealAge > 30,
        dealVelocity,
        isVelocityTrending: dealVelocity < 0,
        avgWinProbability,
        forecastAccuracy,
        needsAttentionDeals: healthMetrics.needsAttentionDeals,
        dealsClosingSoon: healthMetrics.dealsClosingSoon,
        engagementScore,
        riskScore,
        healthScore,
        ...detailedMetrics
      };
    })
    .filter(Boolean);
}

// Add these new helper functions
function calculateEngagementScore(deals) {
  const weights = {
    notes: 0.3,
    recency: 0.4,
    contacts: 0.3
  };
  
  // Use actual notes count
  const avgNotesPerDeal = deals.reduce((sum, deal) => 
    sum + (deal.num_notes || 0), 0) / deals.length;
  
  // Use most recent of last_contacted or last_updated
  const avgDaysSinceContact = deals.reduce((sum, deal) => {
    const lastContact = Math.max(
      new Date(deal.notes_last_contacted || 0).getTime(),
      new Date(deal.notes_last_updated || 0).getTime()
    );
    const daysSince = (Date.now() - lastContact) / (1000 * 60 * 60 * 24);
    return sum + (daysSince || 30);
  }, 0) / deals.length;
  
  // Use actual associated contacts
  const avgContacts = deals.reduce((sum, deal) => 
    sum + (deal.num_associated_contacts || 0), 0) / deals.length;
  
  const scores = {
    notes: Math.min(100, (avgNotesPerDeal / 10) * 100), // Normalize to 10 notes being "good"
    recency: Math.max(0, 100 - (avgDaysSinceContact * 3.33)), // 30 days = 0%
    contacts: Math.min(100, (avgContacts / 3) * 100) // 3 contacts = 100%
  };
  
  return Math.round(
    (scores.notes * weights.notes) +
    (scores.recency * weights.recency) +
    (scores.contacts * weights.contacts)
  );
}

function calculateRiskScore(deals) {
  return deals.reduce((sum, deal) => {
    const weights = {
      stage: 0.3,
      activity: 0.3,
      probability: 0.2,
      completeness: 0.2
    };

    // Stage risk (closed deals = low risk)
    const stageRisk = deal.dealstage === 'closedwon' ? 0 : 
                      deal.dealstage === 'closedlost' ? 100 : 50;

    // Activity risk
    const lastActivity = new Date(deal.notes_last_updated || 0).getTime();
    const daysSinceActivity = (Date.now() - lastActivity) / (1000 * 60 * 60 * 24);
    const activityRisk = Math.min(100, (daysSinceActivity / 14) * 100); // 14 days = 100% risk

    // Probability risk (inverse of win probability)
    const probabilityRisk = 100 - (deal.dealWinProbability * 100);

    // Completeness risk
    const completenessRisk = (
      (deal.missingAmount ? 25 : 0) +
      (deal.missingCloseDate ? 25 : 0) +
      (deal.missingOwner ? 25 : 0) +
      (deal.needsAttention ? 25 : 0)
    );

    return sum + (
      (stageRisk * weights.stage) +
      (activityRisk * weights.activity) +
      (probabilityRisk * weights.probability) +
      (completenessRisk * weights.completeness)
    );
  }, 0) / deals.length;
}

export function HealthCheckProvider({ children }) {
  const { db } = useFirebase();
  const { currentUser, loading: authLoading } = useAuth();
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Raw data states
  const [userData, setUserData] = useState(null);
  const [deals, setDeals] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [engagements, setEngagements] = useState([]);

  // Add owners state
  const [owners, setOwners] = useState({});
  const [ownerMetrics, setOwnerMetrics] = useState(null);

  // Add useEffect for owner metrics calculation
  useEffect(() => {
    if (!deals.length || !owners) return;
    const calculatedMetrics = calculateOwnerMetrics(deals, owners);
    setOwnerMetrics(calculatedMetrics);
  }, [deals, owners]);

  useEffect(() => {
    if (authLoading) return;
    if (!currentUser?.uid) {
      setIsLoading(false);
      setMetrics(DEMO_METRICS);
      return;
    }

    setIsLoading(true);
    setError(null);

    const unsubscribers = [];

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
      const contactsRef = collection(db, 'users', currentUser.uid, 'contacts');
      const engagementsRef = collection(db, 'users', currentUser.uid, 'engagements');
      const ownersRef = collection(db, 'users', currentUser.uid, 'owners');

      // Fetch user data
      const unsubUser = onSnapshot(userRef, (doc) => {
        setUserData({ id: doc.id, ...doc.data() });
      }, (err) => {
        console.error('Error fetching user data:', err);
        setError(err instanceof Error ? err : new Error('Error fetching user data'));
      });
      unsubscribers.push(unsubUser);

      // Fetch deals
      const unsubDeals = onSnapshot(dealsRef, (snapshot) => {
        const fetchedDeals = snapshot.docs
          .map(doc => {
            const data = doc.data();
            // Validate required fields according to schema
            if (!data || typeof data !== 'object') {
              console.warn(`Deal document ${doc.id} has invalid data structure`);
              return null;
            }
            return {
              id: doc.id,
              ...data,
              // Ensure critical fields exist with defaults
              amount: data.amount || 0,
              dealstage: data.dealstage || '',
              hubspot_owner_id: data.hubspot_owner_id || '',
              closedate: data.closedate || null,
              createdate: data.createdate || null
            };
          })
          .filter(Boolean); // Remove null entries
        console.log('Fetched Deals:', fetchedDeals);
        setDeals(fetchedDeals);
      }, (err) => {
        console.error('Error fetching deals:', err);
        setError(err instanceof Error ? err : new Error('Error fetching deals'));
      });
      unsubscribers.push(unsubDeals);

      // Fetch contacts
      const unsubContacts = onSnapshot(contactsRef, (snapshot) => {
        const fetchedContacts = snapshot.docs
          .map(doc => {
            const data = doc.data();
            // Preserve all properties from the original document
            return {
              id: doc.id,
              properties: data.properties || {},
            };
          })
          .filter(Boolean);
        console.log('Fetched Contacts:', fetchedContacts); // For debugging
        setContacts(fetchedContacts);
      }, (err) => {
        console.error('Error fetching contacts:', err);
        setError(err instanceof Error ? err : new Error('Error fetching contacts'));
      });
      unsubscribers.push(unsubContacts);

      // Fetch engagements
      const unsubEngagements = onSnapshot(engagementsRef, (snapshot) => {
        const fetchedEngagements = snapshot.docs
          .map(doc => {
            const data = doc.data();
            // Validate and normalize engagement fields
            return {
              id: doc.id,
              type: data.type ? data.type.toLowerCase() : 'unknown', // Normalize type
              timestamp: data.timestamp ? data.timestamp : Date.now(),
              emailStatus: data.emailStatus ? data.emailStatus.toUpperCase() : 'UNKNOWN', // For email metrics
              ownerId: data.ownerId || '',
              // Include other necessary fields if any
            };
          })
          .filter(engagement => engagement.type === 'call' || engagement.type === 'email'); // Filter valid types
        console.log('Fetched Engagements:', fetchedEngagements); // Added for debugging
        setEngagements(fetchedEngagements);
      }, (err) => {
        console.error('Error fetching engagements:', err);
        setError(err instanceof Error ? err : new Error('Error fetching engagements'));
      });
      unsubscribers.push(unsubEngagements);

      // Add owners subscription
      const unsubOwners = onSnapshot(ownersRef, (snapshot) => {
        const ownersData = {};
        snapshot.forEach((doc) => {
          ownersData[doc.id] = { id: doc.id, ...doc.data() };
        });
        setOwners(ownersData);
      }, (err) => {
        console.error('Error fetching owners:', err);
        setError(err instanceof Error ? err : new Error('Error fetching owners'));
      });
      unsubscribers.push(unsubOwners);
    } catch (err) {
      console.error('Error setting up data fetching:', err);
      setError(err instanceof Error ? err : new Error('Error setting up data fetching'));
      setIsLoading(false);
      return;
    }

    return () => {
      unsubscribers.forEach(unsub => unsub());
    };
  }, [authLoading, currentUser, db]);

  // Data Transformation
  const transformedMetrics = useMemo(() => {
    if (!userData) return null;

    try {
      // Add debug logging
      console.log('Sample contact properties:', contacts[0]?.properties);
      
      // Calculate engagement metrics first
      const engagementMetrics = calculateEngagementMetrics(engagements);

      // Calculate deals completeness
      const dealFields = ['amount', 'stage', 'owner', 'closeDate'];
      const dealsCriticalFields = calculateCriticalFields(deals, dealFields, 'deals');
      const dealsWithNulls = deals.filter(deal => 
        dealFields.some(field => {
          if (field === 'amount') return deal.missingAmount;
          if (field === 'stage') return !deal.dealstage;
          if (field === 'owner') return deal.missingOwner;
          if (field === 'closeDate') return deal.missingCloseDate;
          return false;
        })
      ).length;

      // Calculate contacts completeness
      const contactFields = ['email', 'phone', 'company', 'jobtitle'];
      const contactsCriticalFields = calculateCriticalFields(contacts, contactFields, 'contacts');

      // Calculate email metrics
      const emailMetrics = calculateEmailMetrics(engagements);

      // Return the complete metrics object
      return {
        accountAge: {
          totalDeals: deals.length,
          totalContacts: contacts.length,
        },
        dataHealth: {
          deals: {
            percentComplete: deals.length ? 
              Math.round(((deals.length - dealsWithNulls) / deals.length) * 100) : 0,
            criticalFields: dealsCriticalFields
          },
          contacts: {
            criticalFields: contactsCriticalFields
          }
        },
        activityTrends: calculateActivityTrends(deals, contacts, engagements),
        emailDeliverability: emailMetrics,
        duplicateRate: calculateDuplicateRate(contacts)
      };
    } catch (err) {
      console.error('Error transforming data:', err);
      setError(err);
      return null;
    }
  }, [userData, deals, contacts, engagements]);

  useEffect(() => {
    if (transformedMetrics) {
      setMetrics(transformedMetrics);
    }
    setIsLoading(false);
  }, [transformedMetrics]);

  const value = {
    metrics,
    isLoading,
    error,
    ownerMetrics,
    rawData: { deals, contacts, engagements, owners }
  };

  return (
    <HealthCheckContext.Provider value={value}>
      {children}
    </HealthCheckContext.Provider>
  );
}

// Helper functions
function calculateActivityTrends(deals, contacts, engagements) {
  const months = Array.from({ length: 6 }, (_, i) => {
    const date = new Date();
    date.setMonth(date.getMonth() - i);
    return date;
  }).reverse();

  return months.map(monthDate => {
    const month = monthDate.toLocaleString('default', { month: 'short' });
    const nextMonth = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1);

    // Safe date comparisons
    const monthDeals = deals.filter(deal => {
      const createDate = deal?.createdate ? new Date(deal.createdate) : null;
      return createDate && createDate >= monthDate && createDate < nextMonth;
    }).length;

    const monthContacts = contacts.filter(contact => {
      const createDate = contact?.properties?.createdate ? 
        new Date(contact.properties.createdate) : null;
      return createDate && createDate >= monthDate && createDate < nextMonth;
    }).length;

    const monthEngagements = engagements.filter(engagement => {
      const engagementDate = engagement?.timestamp ? 
        new Date(engagement.timestamp) : null;
      return engagementDate && engagementDate >= monthDate && engagementDate < nextMonth;
    }).length;

    // Calculate activity score (normalized to 100)
    const score = Math.min(100, Math.round(
      ((monthDeals * 2) + monthContacts + (monthEngagements * 0.5)) / 4
    ));

    return {
      month,
      deals: monthDeals,
      contacts: monthContacts,
      score
    };
  });
}

const calculateContactCompleteness = (contacts, fields) => {
  if (!contacts.length) return 0;
  
  const completenessScores = contacts.map(contact => {
    const filledFields = fields.filter(field => 
      Boolean(contact.properties?.[field])
    ).length;
    return (filledFields / fields.length) * 100;
  });
  
  return Math.round(
    completenessScores.reduce((sum, score) => sum + score, 0) / contacts.length
  );
};

const calculateEngagementMetrics = (engagements) => {
  if (!engagements?.length) {
    return {
      callCount: 0,
      emailCount: 0
    };
  }

  const calls = engagements.filter(e => e.type === 'call');
  const emails = engagements.filter(e => e.type === 'email');
  
  return {
    callCount: calls.length,
    emailCount: emails.length
  };
};

export function useHealthCheck() {
  const context = useContext(HealthCheckContext);
  if (!context) {
    throw new Error('useHealthCheck must be used within a HealthCheckProvider');
  }

  return context;
}