   // src/components/common/ErrorBoundary.jsx
   import React from 'react';
   import { 
     Box, 
     Typography, 
     Button, 
     Paper,
     CircularProgress
   } from '@mui/material';
   import { 
     Refresh as RefreshIcon,
     Error as ErrorIcon,
     Timeline as ChartIcon
   } from '@mui/icons-material';
   import logger from '../../utils/frontendLogger';

   class ErrorBoundary extends React.Component {
     constructor(props) {
       super(props);
       this.state = { 
         hasError: false,
         error: null,
         errorInfo: null,
         isRetrying: false
       };
     }

     static getDerivedStateFromError(error) {
       return { 
         hasError: true,
         error 
       };
     }

     componentDidCatch(error, errorInfo) {
       // Check if this is a chart-specific error
       const isChartError = error.message?.includes('map') || 
                           error.message?.includes('undefined') ||
                           error.stack?.includes('Chart');

       this.setState({
         errorInfo,
         isChartError
       });
       
       logger.error('ErrorBoundary caught an error', { 
         error, 
         errorInfo,
         component: this.props.componentName || 'Unknown',
         isChartError
       });
     }

     handleReset = async () => {
       this.setState({ isRetrying: true });
       
       try {
         // If this is a chart error, try to recover the data first
         if (this.state.isChartError && this.props.onChartRetry) {
           await this.props.onChartRetry();
         }

         this.setState({ 
           hasError: false,
           error: null,
           errorInfo: null,
           isRetrying: false
         });
         
         // Call the reset handler if provided
         if (this.props.onReset) {
           await this.props.onReset();
         }
       } catch (error) {
         logger.error('Error during reset:', error);
         this.setState({ 
           isRetrying: false,
           error,
           hasError: true
         });
       }
     }

     render() {
       const { hasError, error, isRetrying, isChartError } = this.state;
       const { 
         height,
         fallbackTitle,
         fallbackMessage,
         compact
       } = this.props;

       if (hasError) {
         // Use compact view for chart errors
         if (compact || isChartError) {
           return (
             <Box
               sx={{
                 height: height || 400,
                 display: 'flex',
                 flexDirection: 'column',
                 justifyContent: 'center',
                 alignItems: 'center',
                 p: 2,
                 bgcolor: 'background.paper',
                 borderRadius: 1
               }}
             >
               {isChartError ? <ChartIcon color="error" sx={{ fontSize: 40, mb: 1 }} />
                            : <ErrorIcon color="error" sx={{ fontSize: 40, mb: 1 }} />}
               
               <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                 {isChartError ? 'Unable to load chart data' 
                             : (fallbackMessage || 'Component failed to load')}
               </Typography>

               {isRetrying ? (
                 <CircularProgress size={24} />
               ) : (
                 <Button
                   size="small"
                   startIcon={<RefreshIcon />}
                   onClick={this.handleReset}
                 >
                   Retry
                 </Button>
               )}
             </Box>
           );
         }

         // Full error view for non-chart errors
         return (
           <Box
             sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               minHeight: height || '400px',
               p: 3
             }}
           >
             <Paper
               elevation={3}
               sx={{
                 p: 4,
                 maxWidth: 500,
                 textAlign: 'center'
               }}
             >
               <ErrorIcon color="error" sx={{ fontSize: 64, mb: 2 }} />
               
               <Typography variant="h5" gutterBottom>
                 {fallbackTitle || 'Something went wrong'}
               </Typography>
               
               <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                 {fallbackMessage || 'We encountered an error while loading this component. Please try again.'}
               </Typography>

               {process.env.NODE_ENV === 'development' && error && (
                 <Typography 
                   variant="body2" 
                   color="error"
                   sx={{ 
                     mb: 3,
                     p: 2,
                     bgcolor: 'error.light',
                     borderRadius: 1,
                     fontFamily: 'monospace',
                     whiteSpace: 'pre-wrap',
                     wordBreak: 'break-word'
                   }}
                 >
                   {error.toString()}
                   {this.state.errorInfo?.componentStack}
                 </Typography>
               )}

               {isRetrying ? (
                 <CircularProgress size={24} />
               ) : (
                 <Button
                   variant="contained"
                   startIcon={<RefreshIcon />}
                   onClick={this.handleReset}
                   sx={{ mt: 2 }}
                 >
                   Try Again
                 </Button>
               )}
             </Paper>
           </Box>
         );
       }

       return this.props.children;
     }
   }

   export default ErrorBoundary;