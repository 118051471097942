import React, { createContext, useContext } from 'react';
import { app, auth, db, functions, analytics, storage } from '../firebaseConfig';
import { 
  doc, 
  collection,
  query,
  where,
  onSnapshot,
  getDocs
} from 'firebase/firestore';
import logger from '../utils/frontendLogger';

const FirebaseContext = createContext(null);

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};

export const FirebaseProvider = ({ children }) => {
  const firebase = {
    app,
    auth,
    db,
    functions,
    analytics,
    storage,
    firestore: {
      doc,
      collection,
      query,
      where,
      onSnapshot,
      getDocs
    }
  };

  logger.info('FirebaseProvider initialized', { 
    hasDb: !!firebase.db,
    hasAuth: !!firebase.auth,
    hasFunctions: !!firebase.functions,
    hasStorage: !!firebase.storage
  });

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};