import React, { useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  Button,
  Divider,
  IconButton,
  useTheme,
  TextField,
  Chip,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const InsightFilters = ({ open, onClose }) => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [confidenceRange, setConfidenceRange] = useState([0, 100]);
  const [selectedTags, setSelectedTags] = useState([]);

  const insightTypes = [
    { label: 'High Value Opportunities', value: 'highValue' },
    { label: 'Risk Alerts', value: 'risk' },
    { label: 'Growth Opportunities', value: 'opportunity' },
    { label: 'Performance Insights', value: 'performance' }
  ];

  const handleTypeChange = (type) => {
    setSelectedTypes(prev => 
      prev.includes(type)
        ? prev.filter(t => t !== type)
        : [...prev, type]
    );
  };

  const handleConfidenceChange = (event, newValue) => {
    setConfidenceRange(newValue);
  };

  const handleAddTag = (event) => {
    if (event.key === 'Enter' && event.target.value) {
      setSelectedTags([...selectedTags, event.target.value]);
      event.target.value = '';
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setSelectedTags(selectedTags.filter(tag => tag !== tagToRemove));
  };

  const handleApplyFilters = () => {
    // Implement filter application logic
    onClose();
  };

  const handleResetFilters = () => {
    setStartDate('');
    setEndDate('');
    setSelectedTypes([]);
    setConfidenceRange([0, 100]);
    setSelectedTags([]);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: { xs: '100%', sm: 400 }, p: 3 }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Filter Insights</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle2" gutterBottom>Date Range</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
          />
        </Box>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle2" gutterBottom>Insight Types</Typography>
        <FormGroup>
          {insightTypes.map((type) => (
            <FormControlLabel
              key={type.value}
              control={
                <Checkbox
                  checked={selectedTypes.includes(type.value)}
                  onChange={() => handleTypeChange(type.value)}
                />
              }
              label={type.label}
            />
          ))}
        </FormGroup>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle2" gutterBottom>AI Confidence Range</Typography>
        <Slider
          value={confidenceRange}
          onChange={handleConfidenceChange}
          valueLabelDisplay="auto"
          min={0}
          max={100}
          sx={{ mt: 2 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Typography variant="caption">{confidenceRange[0]}%</Typography>
          <Typography variant="caption">{confidenceRange[1]}%</Typography>
        </Box>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle2" gutterBottom>Tags</Typography>
        <TextField
          fullWidth
          size="small"
          placeholder="Add tags (press Enter)"
          onKeyDown={handleAddTag}
          sx={{ mb: 2 }}
        />
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
          {selectedTags.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              onDelete={() => handleRemoveTag(tag)}
              sx={{ m: 0.5 }}
            />
          ))}
        </Stack>
      </Box>

      <Box sx={{ mt: 'auto', pt: 2 }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleApplyFilters}
          sx={{ mb: 2 }}
        >
          Apply Filters
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleResetFilters}
        >
          Reset Filters
        </Button>
      </Box>
    </Drawer>
  );
};

export default InsightFilters; 