import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { getFirestore, doc, onSnapshot, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { processDealsForAIInsights, processDealsForAIWatchlist, generateAIInsights } from './utils/overviewProcessing';
import { calculateTruepipeProbability, categorizeDealsByTruepipeProbability } from './utils/truepipeProbability';
import { debounce } from 'lodash';

const OverviewContext = createContext();

export const useOverview = () => useContext(OverviewContext);

export const OverviewProvider = ({ children }) => {
  const [syncProgress, setSyncProgress] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deals, setDeals] = useState([]);
  const [aiInsights, setAiInsights] = useState([]);
  const [aiWatchlist, setAiWatchlist] = useState({ bestDeals: [], criticalDeals: [] });
  const [bestDeals, setBestDeals] = useState([]);
  const [criticalDeals, setCriticalDeals] = useState([]);
  const [owners, setOwners] = useState({});

  const debouncedSetSyncProgress = useMemo(
    () => debounce((newProgress) => setSyncProgress(newProgress), 500),
    []
  );

  const getOwnerName = (ownerId) => {
    const owner = owners[ownerId];
    return owner ? `${owner.firstName} ${owner.lastName}` : 'Unknown Owner';
  };

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const syncDocRef = doc(db, 'users', user.uid, 'syncProgress', 'currentSync');
        
        const unsubscribeSnapshot = onSnapshot(syncDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            debouncedSetSyncProgress(docSnapshot.data());
            setLoading(false);
          } else {
            debouncedSetSyncProgress({});
            setLoading(false);
          }
        }, (err) => {
          console.error('Error in Firestore snapshot listener:', err);
          setError(err.message);
          setLoading(false);
        });

        const dealsRef = collection(db, 'users', user.uid, 'deals');
        const dealsUnsubscribe = onSnapshot(query(dealsRef), (snapshot) => {
          const dealsData = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              closedate: data.closedate ? new Date(data.closedate).getTime() : null,
              createdate: data.createdate ? new Date(data.createdate).getTime() : null,
              amount: parseFloat(data.amount) || 0,
              dealname: data.dealname || 'Unnamed Deal',
              hs_deal_stage_probability: parseFloat(data.hs_deal_stage_probability) || 0,
            };
          });
          
          // Calculate average deal size and expected time in stage
          const averageDealSize = dealsData.reduce((sum, deal) => sum + deal.amount, 0) / dealsData.length;
          const expectedTimeInStage = 30; // This is a placeholder. You might want to calculate this based on your data.

          // Apply truepipe probability to each deal
          const dealsWithTruepipe = dealsData.map(deal => ({
            ...deal,
            truepipeProbability: calculateTruepipeProbability(deal, averageDealSize, expectedTimeInStage)
          }));

          // Categorize deals
          const { bestDeals, criticalDeals } = categorizeDealsByTruepipeProbability(dealsWithTruepipe, averageDealSize, expectedTimeInStage);

          setDeals(dealsWithTruepipe);
          setBestDeals(bestDeals);
          setCriticalDeals(criticalDeals);

          // Process deals for AI watchlist
          const watchlist = processDealsForAIWatchlist(dealsWithTruepipe);
          setAiWatchlist(watchlist);

          // Generate AI insights
          const insights = generateAIInsights(dealsWithTruepipe);
          setAiInsights(insights);
        });

        const ownersRef = collection(db, 'users', user.uid, 'owners');
        const unsubscribeOwners = onSnapshot(ownersRef, (snapshot) => {
          const ownersData = {};
          snapshot.forEach((doc) => {
            ownersData[doc.id] = doc.data();
          });
          setOwners(ownersData);
        }, (err) => {
          console.error('Error fetching owners:', err);
          setError(err.message);
        });

        return () => {
          unsubscribeSnapshot();
          dealsUnsubscribe();
          unsubscribeOwners();
        };
      } else {
        setSyncProgress({});
        setLoading(false);
        setDeals([]);
        setAiInsights([]);
        setAiWatchlist({ bestDeals: [], criticalDeals: [] });
        setBestDeals([]);
        setCriticalDeals([]);
        setOwners({});
      }
    });

    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deals.length > 0 && Object.keys(owners).length > 0) {
      const now = new Date();
      const quarterStart = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
      const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);

      // Calculate performance metrics by owner
      const ownerPerformance = Object.keys(owners).reduce((acc, ownerId) => {
        const ownerDeals = deals.filter(deal => deal.hubspot_owner_id === ownerId);
        const closedDeals = ownerDeals.filter(deal => 
          deal.hs_deal_stage_probability === 1.0 && 
          new Date(deal.closedate) >= quarterStart
        );
        
        const totalRevenue = closedDeals.reduce((sum, deal) => sum + (deal.amount || 0), 0);
        const winRate = ownerDeals.length > 0 
          ? closedDeals.length / ownerDeals.length 
          : 0;

        acc[ownerId] = {
          name: getOwnerName(ownerId),
          dealCount: closedDeals.length,
          totalRevenue,
          winRate,
          deals: closedDeals
        };
        
        return acc;
      }, {});

      // Find top performer based on revenue and deal count
      const topPerformer = Object.entries(ownerPerformance)
        .filter(([, data]) => data.dealCount > 0) // Only consider owners with deals
        .sort(([, a], [, b]) => {
          // Primary sort by revenue
          if (b.totalRevenue !== a.totalRevenue) {
            return b.totalRevenue - a.totalRevenue;
          }
          // Secondary sort by deal count
          if (b.dealCount !== a.dealCount) {
            return b.dealCount - a.dealCount;
          }
          // Tertiary sort by win rate
          return b.winRate - a.winRate;
        })[0];

      // Generate insights array
      const insights = [
        {
          type: 'recentlyClosed',
          message: `${deals.filter(d => new Date(d.closedate) >= twoWeeksAgo && new Date(d.closedate) <= now).length} deals have been closed in the last two weeks.`,
          deals: deals.filter(d => new Date(d.closedate) >= twoWeeksAgo && new Date(d.closedate) <= now)
        },
        {
          type: 'highValue',
          message: 'No high-value deals have been closed this quarter.',
          deals: deals.filter(d => 
            d.amount >= 20000 && 
            new Date(d.closedate) >= quarterStart
          )
        },
        {
          type: 'closedThisQuarter',
          message: `${deals.filter(d => new Date(d.closedate) >= quarterStart && new Date(d.closedate) <= now).length} deals have been closed this quarter.`,
          deals: deals.filter(d => new Date(d.closedate) >= quarterStart && new Date(d.closedate) <= now)
        },
        {
          type: 'topPerformer',
          message: topPerformer 
            ? `${topPerformer[1].name} has closed ${topPerformer[1].dealCount} deals this quarter.`
            : 'No top performer data available.',
          deals: topPerformer ? topPerformer[1].deals : [],
          ownerId: topPerformer ? topPerformer[0] : null,
          dealCount: topPerformer ? topPerformer[1].dealCount : 0,
          totalRevenue: topPerformer ? topPerformer[1].totalRevenue : 0,
          winRate: topPerformer ? topPerformer[1].winRate : 0
        }
      ];

      setAiInsights(insights);
    }
  }, [deals, owners]);

  const value = useMemo(() => ({
    syncProgress,
    loading,
    error,
    deals,
    aiInsights,
    aiWatchlist,
    bestDeals,
    criticalDeals,
    owners,
    getOwnerName,
    calculateOpportunityMetrics,
    calculateRiskMetrics,
    calculateHighValueMetrics
  }), [syncProgress, loading, error, deals, aiInsights, aiWatchlist, bestDeals, criticalDeals, owners]);

  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  );
};

// Define calculateMomentum function
const calculateMomentum = (deal) => {
  // Example calculation for momentum
  return deal.dealVelocity > 0 ? deal.dealVelocity : 0;
};

// Move these functions outside the provider and export them
export const calculateOpportunityMetrics = (deals) => {
  return deals
    .filter(deal => deal.isOpen)
    .map(deal => ({
      date: new Date(deal.createdate),
      opportunityScore: calculateOpportunityScore(deal),
      factors: {
        probability: deal.dealWinProbability,
        engagement: deal.num_contacted_notes,
        momentum: calculateMomentum(deal)
      }
    }));
};

export const calculateRiskMetrics = (deals) => {
  return deals
    .filter(deal => deal.isOpen)
    .map(deal => ({
      date: new Date(deal.createdate),
      riskScore: calculateRiskScore(deal),
      riskFactors: {
        ageRisk: deal.daysOpen > 90,
        velocityRisk: deal.dealVelocity < 0,
        activityRisk: !deal.lastactivitydate,
        engagementRisk: deal.num_contacted_notes < 3
      }
    }));
};

const calculateOpportunityScore = (deal) => {
  let score = 0;
  
  // Win probability (40%)
  score += deal.dealWinProbability * 40;
  
  // Engagement level (30%)
  score += Math.min((deal.num_contacted_notes / 5) * 30, 30);
  
  // Deal momentum (30%)
  score += (deal.dealVelocity > 0) ? 30 : 0;
  
  return score;
};

const calculateRiskScore = (deal) => {
  let score = 0;
  
  // Age risk (30%)
  score += (deal.daysOpen > 90) ? 30 : (deal.daysOpen / 90) * 30;
  
  // Velocity risk (30%)
  score += (deal.dealVelocity < 0) ? 30 : 0;
  
  // Activity risk (20%)
  score += (!deal.lastactivitydate) ? 20 : 0;
  
  // Engagement risk (20%)
  score += (deal.num_contacted_notes < 3) ? 20 : 0;
  
  return score;
};

const calculateHighValueMetrics = (deals, timeframe) => {
  return deals
    .filter(deal => deal.isOpen)
    .map(deal => ({
      date: new Date(deal.createdate),
      value: deal.amount,
      probability: deal.hs_deal_stage_probability,
      weightedValue: deal.amount * deal.hs_deal_stage_probability
    }))
    .sort((a, b) => a.date - b.date);
};