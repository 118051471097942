import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';
import { formatCurrency } from '../components/utils/formatters';
import { useDashboardTheme } from '../hooks/useDashboardTheme';

const ForecastContext = createContext();

export const useForecast = () => {
  const context = useContext(ForecastContext);
  if (!context) {
    throw new Error('useForecast must be used within a ForecastProvider');
  }
  return context;
};

export const ForecastProvider = ({ children }) => {
  const { db } = useFirebase();
  const { currentUser } = useAuth();
  const { colors } = useDashboardTheme();

  // State variables
  const [deals, setDeals] = useState([]);
  const [aggregatedData, setAggregatedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Track separate loading states
  const [dealsLoading, setDealsLoading] = useState(true);
  const [aggregatesLoading, setAggregatesLoading] = useState(true);

  // Initialize forecast metrics
  const [forecastMetrics, setForecastMetrics] = useState({
    currentMRR: 0,
    currentARR: 0,
    forecastedDeals: {
      totalCount: 0,
      totalValue: 0,
      highConfidence: 0,
      mediumConfidence: 0,
      atRisk: 0
    },
    monthlyForecast: [],
    predictedOutcomes: [],
    riskFactors: {
      seasonality: 0,
      marketConditions: 0,
      competitorActivity: 0,
      teamCapacity: 0,
      productUpdates: 0
    },
    growthMetrics: {
      momGrowthRate: 0,
      yoyGrowthRate: 0,
      netRetention: 0
    },
    pipelineCoverage: 0
  });

  // Add after the forecastMetrics state initialization:
  const [dealHealthMetrics, setDealHealthMetrics] = useState({
    engagementHealth: {
      highEngagement: 0,
      moderateEngagement: 0,
      lowEngagement: 0,
      atRisk: 0,
      averageContactFrequency: 0,
      responseRates: 0
    },
    velocityMetrics: {
      averageDaysToClose: 0,
      dealsAboveTarget: 0,
      dealsBelowTarget: 0,
      velocityTrend: 0
    },
    qualityIndicators: {
      multiThreaded: 0,
      singleThreaded: 0,
      recentActivity: 0,
      stale: 0
    }
  });

  // Keep all existing state declarations, but add topMetrics state
  const [topMetrics, setTopMetrics] = useState({
    revenuePerformance: {
      current: 0,
      yoyGrowth: 0,
      trend: [],
      status: 'neutral'
    },
    pipelineHealth: {
      coverage: 0,
      totalValue: 0,
      highConfidence: 0,
      atRisk: 0,
      target: 3.0
    },
    quarterlyForecast: {
      forecast: 0,
      confidence: 0,
      progress: 0,
      daysRemaining: 0
    },
    dealFlow: {
      totalActive: 0,
      avgDealSize: 0,
      distribution: {
        closing30Days: 0,
        closing90Days: 0,
        beyondDeals: 0
      }
    }
  });

  // Add this new state variable
  const [riskFactors, setRiskFactors] = useState({
    dealVelocity: 0,
    dealSize: 0,
    revenueStability: 0,
    pipelineHealth: 0,
    growthMomentum: 0
  });

  // Move monthlyForecast from forecastMetrics state
  const [monthlyForecast, setMonthlyForecast] = useState([]);

  // Add themeColors object near the top of the component
  const themeColors = {
    chart: {
      actual: colors.chart.actual || '#9683EC',
      committed: colors.chart.committed || '#36B5AD',
      baseline: colors.chart.baseline || '#4A90E2',
      optimistic: colors.chart.optimistic || '#33D3B8',
      pessimistic: colors.chart.pessimistic || '#FF9800'
    }
  };

  // Utility functions for calculations
  const calculateDealSizeFromHistory = (dealSizeHistory) => {
    const recentDeals = dealSizeHistory?.slice(-3) || [];
    if (recentDeals.length < 2) return 0;
    
    const currentAvg = recentDeals[recentDeals.length - 1]?.avgDealSize || 0;
    const previousAvg = recentDeals[0]?.avgDealSize || 0;
    
    return previousAvg ? Math.round(((currentAvg - previousAvg) / previousAvg) * 100) : 0;
  };

  const calculateStabilityFromRevenue = (monthlyData) => {
    if (!monthlyData?.length) return 0;
    
    const revenueValues = monthlyData.map(m => m.y);
    const avgRevenue = revenueValues.reduce((a, b) => a + b, 0) / revenueValues.length;
    const variance = revenueValues.reduce((a, b) => a + Math.pow(b - avgRevenue, 2), 0) / revenueValues.length;
    
    return Math.round(-1 * (Math.sqrt(variance) / avgRevenue) * 100);
  };

  const calculateRiskFactors = useCallback((data, deals) => {
    return {
      dealVelocity: Math.round(calculateDealVelocity(deals)),
      dealSize: Math.round(calculateDealSizeTrend(data.avgDealSizeOverTime)),
      revenueStability: Math.round(calculateRevenueStability(data.yoyRevenueComparison)),
      pipelineHealth: Math.round(calculatePipelineCoverage(data, deals)),
      growthMomentum: Math.round(calculateGrowthMomentum(data))
    };
  }, []);

  const initializeHealthMetrics = () => ({
    engagementHealth: {
      highEngagement: 0,
      moderateEngagement: 0,
      lowEngagement: 0,
      atRisk: 0,
      averageContactFrequency: 0,
      responseRates: 0
    },
    velocityMetrics: {
      averageDaysToClose: 0,
      dealsAboveTarget: 0,
      dealsBelowTarget: 0,
      velocityTrend: 0
    },
    qualityIndicators: {
      multiThreaded: 0,
      singleThreaded: 0,
      recentActivity: 0,
      stale: 0
    }
  });

  const updateEngagementMetrics = (metrics, contactFrequency, lastContactDays) => {
    if (contactFrequency >= 0.5 && lastContactDays <= 7) {
      metrics.engagementHealth.highEngagement++;
    } else if (contactFrequency >= 0.3 && lastContactDays <= 14) {
      metrics.engagementHealth.moderateEngagement++;
    } else if (contactFrequency >= 0.1 && lastContactDays <= 30) {
      metrics.engagementHealth.lowEngagement++;
    } else {
      metrics.engagementHealth.atRisk++;
    }
  };

  const updateVelocityMetrics = (metrics, daysOpen) => {
    const targetDays = 90;
    if (daysOpen > targetDays) {
      metrics.velocityMetrics.dealsBelowTarget++;
    } else {
      metrics.velocityMetrics.dealsAboveTarget++;
    }
  };

  const updateQualityIndicators = (metrics, numAssociatedContacts, lastContactDays) => {
    if (numAssociatedContacts > 1) {
      metrics.qualityIndicators.multiThreaded++;
    } else {
      metrics.qualityIndicators.singleThreaded++;
    }

    if (lastContactDays <= 14) {
      metrics.qualityIndicators.recentActivity++;
    } else {
      metrics.qualityIndicators.stale++;
    }
  };

  // Function to process deals into predicted outcomes
  const processDeals = useCallback((deals) => {
    return deals
      // First filter out closed won/lost deals
      .filter(deal => 
        deal.hs_deal_stage_probability !== 1.0 && 
        deal.hs_deal_stage_probability !== 0.0
      )
      // Then map the remaining deals
      .map(deal => ({
        id: deal.id,
        name: deal.dealname || 'Unnamed Deal',
        closeDate: deal.closedate ? new Date(deal.closedate).toLocaleDateString() : 'No date set',
        value: deal.amount || 0,
        probability: Math.round((deal.hs_deal_stage_probability || 0) * 100)
      }))
      .sort((a, b) => b.value - a.value)
      // Optionally limit to top 20
      .slice(0, 20);
  }, []);

  // Add this function after processDeals:
  const calculateDealHealth = useCallback((deals) => {
    const now = new Date();
    
    // First, filter out closed deals
    const activeDeals = deals.filter(deal => 
      deal.hs_deal_stage_probability !== 1.0 && 
      deal.hs_deal_stage_probability !== 0.0
    );
    
    return activeDeals.reduce((metrics, deal) => {
      // Engagement Level Calculation
      const contactFrequency = deal.num_contacted_notes / (deal.daysOpen || 1);
      const lastContactDays = deal.notes_last_contacted ? 
        Math.floor((now - new Date(deal.notes_last_contacted)) / (1000 * 60 * 60 * 24)) : 
        999;
      
      // Determine engagement level
      if (contactFrequency >= 0.5 && lastContactDays <= 7) {
        metrics.engagementHealth.highEngagement++;
      } else if (contactFrequency >= 0.3 && lastContactDays <= 14) {
        metrics.engagementHealth.moderateEngagement++;
      } else if (contactFrequency >= 0.1 && lastContactDays <= 30) {
        metrics.engagementHealth.lowEngagement++;
      } else {
        metrics.engagementHealth.atRisk++;
      }

      // Velocity Metrics
      const targetDays = 90; // Example target sales cycle
      if (deal.daysOpen > targetDays) {
        metrics.velocityMetrics.dealsBelowTarget++;
      } else {
        metrics.velocityMetrics.dealsAboveTarget++;
      }

      // Quality Indicators
      if (deal.num_associated_contacts > 1) {
        metrics.qualityIndicators.multiThreaded++;
      } else {
        metrics.qualityIndicators.singleThreaded++;
      }

      if (lastContactDays <= 14) {
        metrics.qualityIndicators.recentActivity++;
      } else {
        metrics.qualityIndicators.stale++;
      }

      return metrics;
    }, {
      engagementHealth: {
        highEngagement: 0,
        moderateEngagement: 0,
        lowEngagement: 0,
        atRisk: 0,
        averageContactFrequency: 0,
        responseRates: 0
      },
      velocityMetrics: {
        averageDaysToClose: deals.reduce((acc, deal) => acc + (deal.daysOpen || 0), 0) / deals.length,
        dealsAboveTarget: 0,
        dealsBelowTarget: 0,
        velocityTrend: 0
      },
      qualityIndicators: {
        multiThreaded: 0,
        singleThreaded: 0,
        recentActivity: 0,
        stale: 0
      }
    });
  }, []);

  // Add these utility functions before the effects
  const calculateCurrentMRR = (deals) => {
    return deals
      .filter(deal => deal.hs_deal_stage_probability === 1.0) // Only count won deals
      .reduce((total, deal) => total + (deal.amount || 0) / 12, 0); // Convert annual to monthly
  };

  const calculateCurrentARR = (deals) => {
    return deals
      .filter(deal => deal.hs_deal_stage_probability === 1.0)
      .reduce((total, deal) => total + (deal.amount || 0), 0);
  };

  // Then modify the deals effect
  useEffect(() => {
    if (!currentUser || !db) {
      setDealsLoading(false);
      return;
    }

    setDealsLoading(true);
    
    const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
    
    const unsubscribe = onSnapshot(
      dealsRef,
      (snapshot) => {
        const dealsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDeals(dealsData);
        
        // Only update metrics if we have deals data
        if (dealsData.length > 0) {
          const processedDeals = processDeals(dealsData);
          const healthMetrics = calculateDealHealth(dealsData);
          
          setDealHealthMetrics(healthMetrics);
          
          setForecastMetrics(prev => ({
            ...prev,
            currentMRR: calculateCurrentMRR(dealsData),
            currentARR: calculateCurrentARR(dealsData),
            predictedOutcomes: processedDeals,
            forecastedDeals: {
              totalCount: dealsData.length,
              totalValue: dealsData.reduce((sum, deal) => sum + (deal.amount || 0), 0),
              highConfidence: dealsData.filter(d => (d.hs_deal_stage_probability || 0) > 0.7).length,
              mediumConfidence: dealsData.filter(d => (d.hs_deal_stage_probability || 0) > 0.3 && (d.hs_deal_stage_probability || 0) <= 0.7).length,
              atRisk: dealsData.filter(d => (d.hs_deal_stage_probability || 0) <= 0.3).length
            }
          }));

          setTopMetrics(prev => ({
            ...prev,
            dealFlow: calculateDealFlow(dealsData)
          }));
        }
        
        setDealsLoading(false);
      },
      (err) => {
        console.error('Error fetching deals:', err);
        setError(err);
        setDealsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser, db, processDeals, calculateDealHealth]);

  // Add these utility functions at the top of ForecastContext
  const calculateDealVelocity = (deals) => {
    const openDeals = deals.filter(deal => deal.isOpenDeal);
    if (!openDeals.length) return 0;

    // Use actual daysOpen from deals
    const avgDaysOpen = openDeals.reduce((sum, deal) => sum + Math.abs(deal.daysOpen || 0), 0) / openDeals.length;
    const targetCycle = 90;
    
    // Higher score = faster velocity
    const velocityScore = ((targetCycle - avgDaysOpen) / targetCycle) * 100;
    return Math.min(100, Math.max(-100, velocityScore));
  };

  const calculateDealSizeTrend = (avgDealSizeOverTime) => {
    if (!avgDealSizeOverTime?.length) return 0;
    
    // Get last 6 months with actual deal sizes
    const validMonths = avgDealSizeOverTime
      .filter(month => month.avgDealSize > 0)
      .slice(-6);
    
    if (validMonths.length < 4) return 0; // Need at least 4 months for comparison
    
    const last3 = validMonths.slice(-3);
    const prev3 = validMonths.slice(-6, -3);
    
    const recentAvg = last3.reduce((sum, m) => sum + m.avgDealSize, 0) / last3.length;
    const prevAvg = prev3.reduce((sum, m) => sum + m.avgDealSize, 0) / prev3.length;
    
    return ((recentAvg - prevAvg) / prevAvg) * 100;
  };

  const calculateRevenueStability = (yoyRevenueComparison) => {
    if (!yoyRevenueComparison?.[0]?.data) return 0;
    
    // Get current year's monthly revenue
    const monthlyRevenues = yoyRevenueComparison[0].data
      .filter(m => m.y > 0)
      .map(m => m.y);
    
    if (monthlyRevenues.length < 3) return 0;
    
    const mean = monthlyRevenues.reduce((sum, rev) => sum + rev, 0) / monthlyRevenues.length;
    const variance = monthlyRevenues.reduce((sum, rev) => 
      sum + Math.pow(rev - mean, 2), 0) / monthlyRevenues.length;
    const stdDev = Math.sqrt(variance);
    
    // Convert to stability score (lower variation = higher stability)
    return Math.max(0, 100 - (stdDev / mean * 100));
  };

  const calculatePipelineCoverage = (data, deals) => {
    const quarterlyTarget = data.quarterlyRevenue || 1;
    
    // Calculate weighted pipeline value from open deals
    const pipelineValue = deals
      .filter(deal => deal.isOpenDeal)
      .reduce((sum, deal) => sum + (
        (deal.amount || 0) * (deal.hs_deal_stage_probability || 0)
      ), 0);
    
    // Compare to 3x quarterly target
    const coverageRatio = pipelineValue / (quarterlyTarget * 3);
    return Math.min(100, coverageRatio * 100);
  };

  const calculateGrowthMomentum = (data) => {
    const monthlyData = data.yoyRevenueComparison?.[0]?.data || [];
    
    // Get last 3 months with revenue
    const nonZeroMonths = monthlyData
      .filter(m => m.y > 0)
      .slice(-3);
    
    if (nonZeroMonths.length < 2) return 0;
    
    // Calculate average growth rate over available months
    const growthRates = [];
    for (let i = 1; i < nonZeroMonths.length; i++) {
      const rate = ((nonZeroMonths[i].y - nonZeroMonths[i-1].y) / nonZeroMonths[i-1].y) * 100;
      growthRates.push(rate);
    }
    
    // Use average growth rate to match Projected MRR calculation
    return growthRates.reduce((a, b) => a + b, 0) / growthRates.length;
  };

  // Add these calculation functions before the effects
  const calculateRevenuePerformance = (data) => {
    const monthlyRevenue = data.monthlyRevenue || 0;
    const yoyGrowth = data.yoyRevenueComparison?.[1]?.data.reduce((acc, month) => acc + month.y, 0) || 0;
    const currentYearRevenue = data.yoyRevenueComparison?.[0]?.data.reduce((acc, month) => acc + month.y, 0) || 0;
    const yoyGrowthRate = yoyGrowth ? ((currentYearRevenue - yoyGrowth) / yoyGrowth) * 100 : 0;
    
    const lastThreeMonths = data.yoyRevenueComparison?.[0]?.data
      .filter(m => m.y > 0)
      .slice(-3)
      .map(m => ({
        month: m.x,
        value: m.y
      }));

    return {
      current: monthlyRevenue,
      yoyGrowth: yoyGrowthRate,
      trend: lastThreeMonths,
      status: yoyGrowthRate >= 0 ? 'positive' : 'negative'
    };
  };

  const calculatePipelineHealth = (data, deals) => {
    const quarterlyTarget = data.quarterlyRevenue || 1;
    const openDeals = deals.filter(deal => deal.isOpenDeal);
    
    const pipelineValue = openDeals.reduce((sum, deal) => 
      sum + ((deal.amount || 0) * (deal.hs_deal_stage_probability || 0)), 0);
    
    const coverage = pipelineValue / (quarterlyTarget * 3);
    
    const highConfidence = openDeals.filter(d => (d.hs_deal_stage_probability || 0) > 0.7);
    const atRisk = openDeals.filter(d => (d.hs_deal_stage_probability || 0) <= 0.3);

    return {
      coverage: Number(coverage.toFixed(1)),
      totalValue: pipelineValue,
      highConfidence: highConfidence.length,
      atRisk: atRisk.length,
      target: 3.0
    };
  };

  const calculateQuarterlyForecast = (data, deals) => {
    const quarterlyTarget = data.quarterlyRevenue || 0;
    
    const openDeals = deals.filter(deal => deal.isOpenDeal);
    const weightedPipeline = openDeals.reduce((sum, deal) => 
      sum + ((deal.amount || 0) * (deal.hs_deal_stage_probability || 0)), 0);
    
    const coverage = weightedPipeline / quarterlyTarget;
    const highQualityDeals = openDeals.filter(d => 
      d.hs_deal_stage_probability > 0.7 && d.num_associated_contacts > 2).length;
    const confidenceScore = Math.min(100, Math.round((coverage * 0.7 + (highQualityDeals / openDeals.length) * 0.3) * 100));
    
    const now = new Date();
    const quarterEnd = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3 + 3, 0);
    const daysRemaining = Math.ceil((quarterEnd - now) / (1000 * 60 * 60 * 24));

    return {
      forecast: weightedPipeline,
      confidence: confidenceScore,
      progress: (weightedPipeline / quarterlyTarget) * 100,
      daysRemaining
    };
  };

  const calculateDealFlow = (deals) => {
    const activeDeals = deals.filter(deal => deal.isOpenDeal);
    const now = new Date();
    
    const totalValue = activeDeals.reduce((sum, deal) => sum + (deal.amount || 0), 0);
    const avgDealSize = activeDeals.length ? totalValue / activeDeals.length : 0;
    
    const dealsByTiming = activeDeals.reduce((acc, deal) => {
      const closeDate = new Date(deal.closedate);
      const daysToClose = Math.ceil((closeDate - now) / (1000 * 60 * 60 * 24));
      
      if (daysToClose <= 30) acc.closing30Days++;
      else if (daysToClose <= 90) acc.closing90Days++;
      else acc.beyondDeals++;
      
      return acc;
    }, { closing30Days: 0, closing90Days: 0, beyondDeals: 0 });

    return {
      totalActive: activeDeals.length,
      avgDealSize,
      distribution: dealsByTiming
    };
  };

  // Add these utility functions after the existing calculation functions
  const monthsDifference = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return (d2.getFullYear() - d1.getFullYear()) * 12 + (d2.getMonth() - d1.getMonth());
  };

  const getMonthLabel = (monthsAhead) => {
    const date = new Date();
    date.setMonth(date.getMonth() + monthsAhead);
    return date.toLocaleString('default', { month: 'short', year: '2-digit' });
  };

  const calculateGrowthRate = (recentMonths) => {
    if (recentMonths.length < 2) return 0;
    const monthlyRates = [];
    for (let i = 1; i < recentMonths.length; i++) {
      if (recentMonths[i-1].y > 0) {
        monthlyRates.push((recentMonths[i].y - recentMonths[i-1].y) / recentMonths[i-1].y);
      }
    }
    return monthlyRates.reduce((sum, rate) => sum + rate, 0) / monthlyRates.length;
  };

  // Add this function after processMonthlyForecast
  const calculateForecastScenarios = (historicalData, openDeals) => {
    if (!historicalData?.length || !openDeals?.length) {
      return null;
    }

    // Calculate base growth rate from historical data
    const baseGrowthRate = calculateGrowthRate(historicalData);
    
    // Get the most recent revenue value as starting point
    const lastKnownRevenue = historicalData[historicalData.length - 1].y;
    
    // Calculate weighted pipeline value for next 6 months
    const pipelineByMonth = openDeals.reduce((acc, deal) => {
      if (!deal.closedate) return acc;
      
      const closeMonth = new Date(deal.closedate).toLocaleString('default', { month: 'short', year: '2-digit' });
      const weightedValue = (deal.amount || 0) * (deal.hs_deal_stage_probability || 0);
      
      acc[closeMonth] = (acc[closeMonth] || 0) + weightedValue;
      return acc;
    }, {});

    // Generate 6 months of forecasts
    const generateForecast = (growthModifier = 1) => {
      const forecast = [];
      let currentValue = lastKnownRevenue;

      for (let i = 0; i < 6; i++) {
        const month = getMonthLabel(i + 1);
        const pipelineValue = pipelineByMonth[month] || 0;
        
        // Apply growth rate and add pipeline value
        currentValue = currentValue * (1 + (baseGrowthRate * growthModifier)) + pipelineValue;
        
        forecast.push({
          month,
          forecast: Math.round(currentValue),
          committed: pipelineValue
        });
      }

      return forecast;
    };

    // Generate baseline, best case, and worst case scenarios
    return {
      baseline: generateForecast(1), // Normal growth
      bestCase: generateForecast(1.5), // 50% better growth
      worstCase: generateForecast(0.5) // 50% worse growth
    };
  };

  // Modify the processMonthlyForecast function
  const processMonthlyForecast = (data, deals) => {
    if (!data?.yoyRevenueComparison?.[0]?.data) return [];
    
    const historicalData = data.yoyRevenueComparison[0].data
      .filter(m => m.y > 0)
      .slice(-6);

    const openDeals = deals.filter(deal => 
      deal.hs_deal_stage_probability !== 1.0 && 
      deal.hs_deal_stage_probability !== 0.0
    );

    try {
      const scenarios = calculateForecastScenarios(historicalData, openDeals);
      
      if (!scenarios?.baseline) {
        console.warn('Invalid scenarios data:', scenarios);
        return historicalData.map(m => ({
          month: m.x,
          actual: m.y,
          committed: 0,
          forecast: 0
        }));
      }

      // Ensure all values are numbers and not null
      return [
        ...historicalData.map(m => ({
          month: m.x,
          actual: Number(m.y) || 0,
          committed: 0,
          forecast: 0
        })),
        ...scenarios.baseline.map((m, i) => ({
          month: m.month,
          actual: 0,
          committed: Number(m.committed) || 0,
          forecast: Number(m.forecast) || 0
        }))
      ];
    } catch (error) {
      console.error('Error processing monthly forecast:', error);
      return [];
    }
  };

  // Effect to fetch and process aggregates
  useEffect(() => {
    if (!currentUser || !db) {
      setAggregatesLoading(false);
      return;
    }

    setAggregatesLoading(true);
    
    const aggregatesRef = doc(db, 'users', currentUser.uid, 'aggregates', 'revenue');
    
    const unsubscribe = onSnapshot(
      aggregatesRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setAggregatedData(data);
          
          // Update revenue performance metrics
          setTopMetrics(prev => ({
            ...prev,
            revenuePerformance: calculateRevenuePerformance(data),
            pipelineHealth: calculatePipelineHealth(data, deals),
            quarterlyForecast: calculateQuarterlyForecast(data, deals)
          }));
          
          // Update forecast metrics with processed monthly forecast
          const processedForecast = processMonthlyForecast(data, deals);
          setMonthlyForecast(processedForecast); // Set monthlyForecast separately
          
          // Calculate and update risk factors
          setRiskFactors(calculateRiskFactors(data, deals));
        }
        
        setAggregatesLoading(false);
      },
      (error) => {
        console.error('Error fetching aggregates:', error);
        setError(error);
        setAggregatesLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser, db, deals, calculateRiskFactors]);

  // Update overall loading state when both deals and aggregates are loaded
  useEffect(() => {
    setLoading(dealsLoading || aggregatesLoading);
  }, [dealsLoading, aggregatesLoading]);

  // Add new standardized forecast data processor
  const standardizeForecastData = (monthlyForecast) => {
    if (!monthlyForecast?.length) return null;

    return {
      timeSeriesData: monthlyForecast.map(month => ({
        date: month.month,
        baseline: month.forecast,
        optimistic: month.bestCase,
        pessimistic: month.worstCase,
        actual: month.actual,
        committed: month.committed
      })),
      timeSeriesConfig: {
        yAxisFormatter: formatCurrency,
        tooltipFormatter: formatCurrency,
        series: [
          {
            dataKey: 'actual',
            name: 'Actual Revenue',
            color: themeColors.chart.actual,
            type: 'solid',
            tooltipLabel: 'Actual'
          },
          {
            dataKey: 'committed',
            name: 'Committed Pipeline',
            color: themeColors.chart.committed,
            type: 'solid',
            tooltipLabel: 'Committed'
          },
          {
            dataKey: 'baseline',
            name: 'Baseline Forecast',
            color: themeColors.chart.baseline,
            type: 'dashed',
            tooltipLabel: 'Baseline'
          },
          {
            dataKey: 'optimistic',
            name: 'Optimistic Forecast',
            color: themeColors.chart.optimistic,
            type: 'dashed',
            tooltipLabel: 'Optimistic'
          },
          {
            dataKey: 'pessimistic',
            name: 'Pessimistic Forecast',
            color: themeColors.chart.pessimistic,
            type: 'dashed',
            tooltipLabel: 'Pessimistic'
          }
        ]
      }
    };
  };

  // Keep just the contextValue
  const contextValue = {
    deals,
    aggregatedData,
    ...forecastMetrics,
    monthlyForecast,
    dealHealthMetrics,
    loading,
    error,
    formatCurrency,
    topMetrics,
    riskFactors,
    standardizedForecastData: standardizeForecastData(monthlyForecast)
  };

  return (
    <ForecastContext.Provider value={contextValue}>
      {children}
    </ForecastContext.Provider>
  );
};

export default ForecastProvider;