// Base color tokens
const baseColors = {
  primary: '#BABDE2',    // periwinkle
  secondary: '#00C0A0',  // mint
  success: '#33D3B8',    // turquoise
  warning: '#FEA58E',    // melon
  error: '#FF4400',      // hubspotOrange
  tertiary: '#8285A1',   // coolGray
  quaternary: '#66E6D1',  // turquoiseLight
  neutral: '#737373',     // neutral gray
  info: '#2196f3',       // info blue
  purple: '#BABDE2',     // matching status.purple
  committed: '#4CAF50',   // committed green
  forecast: '#9C27B0',    // forecast purple
  target: '#E91E63',     // target pink
  background: '#F5F8FA',   // default light background
  // Add specific chart colors
  chart: {
    actual: '#FFD700',   // gold (kept from original)
    committed: '#4CAF50',
    forecast: '#9C27B0',
    target: '#E91E63',
    grid: 'rgba(0, 0, 0, 0.1)'
  }
};

// CTA color tokens
const ctaTokens = {
  dark: {
    background: 'rgba(54, 181, 173, 0.08)',
    border: 'rgba(54, 181, 173, 0.2)',
    button: 'rgba(54, 181, 173, 0.9)',
    buttonHover: 'rgba(54, 181, 173, 1)',
  },
  light: {
    background: 'rgba(54, 181, 173, 0.05)',
    border: 'rgba(54, 181, 173, 0.1)',
    button: 'rgba(54, 181, 173, 0.8)',
    buttonHover: 'rgba(54, 181, 173, 0.9)',
  }
};

// Chart palette tokens
const chartPalette = [
  baseColors.primary,
  baseColors.secondary,
  baseColors.warning,
  baseColors.success,
  baseColors.error
];

// Dashboard tokens based on mode
const getThemeTokens = (mode) => ({
  background: mode === 'light' ? '#F5F8FA' : '#121212', // antiFlashWhite : dark
  card: {
    background: mode === 'light' ? '#FFFFFF' : '#1E1E1E', // white : eerieBlack
    hover: mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : '#2A2A2A',
    border: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : '#333333',
    text: mode === 'light' ? '#1A1A1A' : '#FFFFFF',
  },
  text: {
    primary: mode === 'light' ? '#2E3A59' : '#FFFFFF',   // deepBlue : white
    secondary: mode === 'light' ? '#4B5D73' : '#B3B3B3', // slateBlue : lightGray
    tertiary: mode === 'light' ? '#33475B' : '#737373',  // charcoal : mediumGray
  },
  chart: {
    primary: mode === 'light' ? '#8285A1' : '#BABDE2',   // coolGray : periwinkle
    secondary: mode === 'light' ? '#00C0A0' : '#33D3B8', // mint : turquoise
    accent: mode === 'light' ? '#FEA58E' : '#FF4400',    // melon : hubspotOrange
    success: baseColors.success,
    error: baseColors.error,
    grid: mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.08)',
    deals: mode === 'light' ? '#8285A1' : '#BABDE2',     // coolGray : periwinkle
    contacts: mode === 'light' ? '#00C0A0' : '#33D3B8',  // mint : turquoise
    score: mode === 'light' ? '#FEA58E' : '#FF4400',     // melon : hubspotOrange
    baseline: mode === 'light' ? '#8285A1' : '#BABDE2',  // coolGray : periwinkle
    optimistic: mode === 'light' ? '#00C0A0' : '#33D3B8',// mint : turquoise
    pessimistic: mode === 'light' ? '#FEA58E' : '#FF4400',// melon : hubspotOrange
    actual: '#FFD700',  // gold
    palette: chartPalette,
    tertiary: baseColors.tertiary,
    quaternary: baseColors.quaternary,
  },
  status: {
    success: baseColors.success,
    warning: baseColors.warning,
    error: baseColors.error,
  },
  progressBar: {
    background: mode === 'light' ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.08)',
    primary: mode === 'light' ? baseColors.primary : baseColors.primary,
    secondary: mode === 'light' ? baseColors.secondary : baseColors.secondary,
  },
  cta: {
    background: ctaTokens[mode].background,
    border: ctaTokens[mode].border,
    button: ctaTokens[mode].button,
    buttonHover: ctaTokens[mode].buttonHover,
    text: mode === 'light' ? '#1A1A1A' : '#FFFFFF',
  },
});

// Component-specific theme settings
const components = {
  card: {
    borderRadius: 8,
    padding: 24,
    elevation: 0,
  },
  chart: {
    height: 300,
    barRadius: [4, 4, 0, 0],
  },
};

// Create and export the theme object
export const dashboardTheme = {
  tokens: getThemeTokens,
  components,
  colors: baseColors
};