import React, { useMemo } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Tooltip,
  LinearProgress
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import { Sparklines, SparklinesLine, SparklinesSpots, SparklinesReferenceLine } from 'react-sparklines';
import { useOverview } from '../../../contexts/OverviewContext';
import { format } from 'date-fns';
import { calculateOpportunityMetrics, calculateRiskMetrics } from '../../../contexts/OverviewContext';

const InsightsTrends = () => {
  const theme = useTheme();
  const [timeRange, setTimeRange] = React.useState('30d');
  const [metricType, setMetricType] = React.useState('value');
  const { deals, aiInsights } = useOverview();

  const opportunityMetrics = useMemo(() => calculateOpportunityMetrics(deals), [deals]);
  const riskMetrics = useMemo(() => calculateRiskMetrics(deals), [deals]);

  const trendData = useMemo(() => {
    const now = new Date();
    const daysMap = { '7d': 7, '30d': 30, '90d': 90 };
    const days = daysMap[timeRange];
    const startDate = new Date(now.setDate(now.getDate() - days));

    // Group deals by date
    const groupedDeals = deals.reduce((acc, deal) => {
      const date = new Date(deal.closedate).toISOString().split('T')[0];
      if (!acc[date]) acc[date] = { value: 0, count: 0, deals: [] };
      acc[date].value += deal.amount;
      acc[date].count += 1;
      acc[date].deals.push(deal);
      return acc;
    }, {});

    // Create arrays for each metric
    return {
      highValue: Object.values(groupedDeals).map(day => 
        metricType === 'value' ? day.value : day.count
      ),
      risk: riskMetrics.map(metric => metric.riskScore),
      opportunity: opportunityMetrics.map(metric => metric.opportunityScore),
      performance: Object.values(groupedDeals)
        .map(day => day.deals
          .filter(deal => deal.truepipeProbability > 0.7).length)
    };
  }, [deals, aiInsights, timeRange, metricType, opportunityMetrics, riskMetrics]);

  const distributionData = useMemo(() => ({
    highValue: 65,
    risk: 45,
    opportunity: 35,
    performance: 25
  }), []);

  const renderSparkline = (data, color) => (
    <Box sx={{ 
      height: 40,
      width: '100%',
      display: 'flex',
      alignItems: 'center'
    }}>
      <Sparklines 
        data={data} 
        margin={5}
        height={20}
      >
        <SparklinesLine 
          style={{ 
            stroke: color, 
            strokeWidth: 1,
            fill: 'none'
          }} 
        />
        <SparklinesSpots 
          size={1}
          style={{ 
            fill: color,
            stroke: 'white',
            strokeWidth: .1
          }} 
        />
        <SparklinesReferenceLine 
          type="mean"
          style={{ 
            stroke: 'rgba(0,0,0,0.2)', 
            strokeDasharray: '2, 2' 
          }} 
        />
      </Sparklines>
      <Typography variant="caption" sx={{ ml: 1, minWidth: 60 }}>
        {metricType === 'value' 
          ? `$${Math.max(...data).toLocaleString()}`
          : Math.max(...data)}
      </Typography>
    </Box>
  );

  const renderDistributionBar = (value, color) => (
    <Box sx={{ width: '100%', mt: 1 }}>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          height: 20,
          borderRadius: 1,
          backgroundColor: theme.palette.grey[200],
          '& .MuiLinearProgress-bar': {
            backgroundColor: color,
            borderRadius: 1,
          },
        }}
      />
    </Box>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        {/* Controls */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Time Range</InputLabel>
              <Select
                value={timeRange}
                label="Time Range"
                onChange={(e) => setTimeRange(e.target.value)}
              >
                <MenuItem value="7d">Last 7 days</MenuItem>
                <MenuItem value="30d">Last 30 days</MenuItem>
                <MenuItem value="90d">Last 90 days</MenuItem>
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Metric</InputLabel>
              <Select
                value={metricType}
                label="Metric"
                onChange={(e) => setMetricType(e.target.value)}
              >
                <MenuItem value="value">Deal Value</MenuItem>
                <MenuItem value="count">Deal Count</MenuItem>
                <MenuItem value="confidence">AI Confidence</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              size="small"
            >
              Export Data
            </Button>
          </Box>
        </Grid>

        {/* Main Trend Charts */}
        <Grid item xs={12} lg={8}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Insight Trends Over Time</Typography>
                <Tooltip title="Shows the trend of different insight types over the selected time period">
                  <IconButton size="small">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Grid container spacing={2}>
                {Object.entries(trendData).map(([key, data]) => (
                  <Grid item xs={12} key={key}>
                    <Box sx={{ 
                      mb: 2,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}>
                      <Typography 
                        variant="subtitle2" 
                        sx={{ 
                          minWidth: '150px',  // Fixed width for labels
                          flexShrink: 0 
                        }}
                      >
                        {key.charAt(0).toUpperCase() + key.slice(1)} Insights
                      </Typography>
                      {renderSparkline(data, theme.palette[
                        key === 'highValue' ? 'success' : 
                        key === 'risk' ? 'error' : 
                        key === 'opportunity' ? 'warning' : 'info'
                      ].main)}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Distribution Chart */}
        <Grid item xs={12} lg={4}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Insight Distribution</Typography>
                <Tooltip title="Distribution of different types of insights">
                  <IconButton size="small">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              {Object.entries(distributionData).map(([key, value]) => (
                <Box key={key} sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2">
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </Typography>
                    <Typography variant="subtitle2">
                      {value}%
                    </Typography>
                  </Box>
                  {renderDistributionBar(value, theme.palette[key === 'highValue' ? 'success' : 
                                                            key === 'risk' ? 'error' : 
                                                            key === 'opportunity' ? 'warning' : 'info'].main)}
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>

        {/* Summary Cards */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {Object.entries(distributionData).map(([key, value]) => (
              <Grid item xs={12} sm={6} md={3} key={key}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="textSecondary">
                      {key.charAt(0).toUpperCase() + key.slice(1)} Insights
                    </Typography>
                    <Typography variant="h4">
                      {Math.floor(Math.random() * 100)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      +{Math.floor(Math.random() * 20)}% from last period
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsightsTrends; 