import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, Icon, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, useTheme } from '@mui/material';
import { useOverview } from '../../../contexts/OverviewContext';
import { useChartStyles } from '../../hooks/useChartStyles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const insightTypeIcons = {
  recentlyClosed: TrendingUpIcon,
  highValue: TrendingUpIcon,
  closedThisQuarter: TrendingUpIcon,
  topPerformer: TrendingUpIcon,
};

const StatusCell = ({ status }) => {
  const theme = useTheme();
  const isWon = status === 'Won';
  
  const backgroundColor = theme.palette.mode === 'dark'
    ? (isWon ? '#33D3B8' : '#FF8585')
    : (isWon ? '#00B090' : '#FF6666');
  
  const color = theme.palette.getContrastText(backgroundColor);

  return (
    <Box
      sx={{
        backgroundColor,
        color,
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'inline-block',
        fontWeight: 'bold',
      }}
    >
      {status}
    </Box>
  );
};

const AIInsightsChart = () => {
  const { aiInsights, loading, error } = useOverview();
  const { 
    getCommonChartProperties, 
    getChartColor, 
    isMobile, 
    getTooltipStyles,
    getTableStyles
  } = useChartStyles();
  const [selectedInsight, setSelectedInsight] = useState(null);
  const theme = useTheme();

  if (loading) {
    return <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography>Loading AI insights...</Typography>
    </Box>;
  }

  if (error) {
    return <Typography color="error">Error loading AI insights: {error}</Typography>;
  }

  const generateSparklineData = (deals) => {
    return deals && deals.length > 0 ? deals.map(deal => deal.amount) : [];
  };

  const chartProps = getCommonChartProperties('line');
  const tableStyles = getTableStyles();

  const handleCardClick = (index) => {
    setSelectedInsight(selectedInsight === index ? null : index);
  };

  const renderInsightDetails = (insight) => {
    if (insight.type === 'topPerformer') {
      return (
        <Typography variant="body2">
          {insight.message}
          {insight.dealCount > 0 && (
            <>
              <br />
              <Typography component="span" sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                Total Revenue: ${insight.totalRevenue.toLocaleString()}
                <br />
                Win Rate: {(insight.winRate * 100).toFixed(1)}%
              </Typography>
            </>
          )}
        </Typography>
      );
    } else if (insight.deals && insight.deals.length > 0) {
      return (
        <TableContainer component={Paper} sx={{ maxHeight: 300, overflowY: 'auto' }}>
          <Table stickyHeader aria-label="deals table" size="small" sx={tableStyles.table}>
            <TableHead sx={tableStyles.tableHead}>
              <TableRow>
                <TableCell>Deal Name</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Close Date</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {insight.deals && insight.deals.map((deal, dealIndex) => (
                <TableRow key={dealIndex} sx={tableStyles.tableRow}>
                  <TableCell component="th" scope="row">
                    {deal.dealname}
                  </TableCell>
                  <TableCell align="right">${deal.amount.toLocaleString()}</TableCell>
                  <TableCell align="right">{new Date(deal.closedate).toLocaleDateString()}</TableCell>
                  <TableCell align="right">
                    <StatusCell status={deal.hs_deal_stage_probability === 1 ? 'Won' : 'Lost'} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <Typography variant="body2">No deal data available for this insight.</Typography>
      );
    }
  };

  return (
    <Box sx={{ height: '100%', p: isMobile ? 1 : 2 }}>
      <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>AI Insights Deals Focus</Typography>
      <Grid container spacing={isMobile ? 1 : 2} sx={{ mb: isMobile ? 1 : 2 }}>
        {aiInsights.map((insight, index) => {
          const IconComponent = insightTypeIcons[insight.type] || TrendingUpIcon;
          const color = getChartColor(index);
          const sparklineData = generateSparklineData(insight.deals);

          return (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                minHeight: isMobile ? '160px' : 'auto',
              }}>
                <CardContent 
                  sx={{ 
                    flexGrow: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    cursor: 'pointer',
                    padding: theme.spacing(1.5),
                  }}
                  onClick={() => handleCardClick(index)}
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                    <Box display="flex" alignItems="center">
                      <Icon component={IconComponent} sx={{ color, mr: 1, fontSize: '1.2rem' }} />
                      <Typography variant={isMobile ? "body2" : "body1"} color={color}>
                        {insight.type}
                      </Typography>
                    </Box>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCardClick(index);
                      }}
                    >
                      {selectedInsight === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                  <Typography 
                    variant="body2"
                    sx={{ mb: 1, flexGrow: 1, lineHeight: 1.4 }}
                  >
                    {insight.message}
                  </Typography>
                  {sparklineData.length > 0 && (
                    <Tooltip
                      title="Deal amounts trend"
                      placement="top"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: getTooltipStyles().container,
                        },
                      }}
                    >
                      <Box sx={{ mb: 0.5 }}>
                        <Sparklines data={sparklineData} width={100} height={20}>
                          <SparklinesLine color={color} />
                        </Sparklines>
                      </Box>
                    </Tooltip>
                  )}
                  {!isMobile && (
                    <Typography variant="caption" color="textSecondary">
                      {insight.deals ? `${insight.deals.length} deals affected` : 'No deals affected'}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {selectedInsight !== null && renderInsightDetails(aiInsights[selectedInsight])}
    </Box>
  );
};

export default React.memo(AIInsightsChart);