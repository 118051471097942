import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tabs,
  Tab,
  LinearProgress,
  Collapse,
  Grid,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useTheme,
  useMediaQuery
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircleIcon from '@mui/icons-material/Circle';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TimelineIcon from '@mui/icons-material/Timeline';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { alpha } from '@mui/material/styles';
import { useOverview } from '../../../contexts/OverviewContext';

// Import calculation functions
const calculateOpportunityScore = (deal) => {
  let score = 0;
  
  // Win probability (40%)
  score += (deal.truepipeProbability || 0) * 40;
  
  // Engagement level (30%)
  const engagementScore = Math.min(((deal.num_contacted_notes || 0) / 5) * 30, 30);
  score += engagementScore;
  
  // Deal momentum (30%)
  score += (deal.dealVelocity > 0) ? 30 : 0;
  
  return score;
};

const calculateRiskScore = (deal) => {
  let score = 0;
  
  // Age risk (30%)
  const daysOpen = deal.daysOpen || 0;
  score += (daysOpen > 90) ? 30 : (daysOpen / 90) * 30;
  
  // Velocity risk (30%)
  score += (deal.dealVelocity < 0) ? 30 : 0;
  
  // Activity risk (20%)
  score += (!deal.lastactivitydate) ? 20 : 0;
  
  // Engagement risk (20%)
  score += ((deal.num_contacted_notes || 0) < 3) ? 20 : 0;
  
  return score;
};

// Utility functions
const formatAmount = (amount) => {
  if (amount >= 1000000) return `${(amount / 1000000).toFixed(1)}M`;
  if (amount >= 1000) return `${(amount / 1000).toFixed(1)}K`;
  return amount.toString();
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString();
};

const getProgressColor = (probability, theme) => {
  if (probability >= 0.7) return theme.palette.success.main;
  if (probability >= 0.4) return theme.palette.warning.main;
  return theme.palette.error.main;
};

// Detail Components
const MetricBox = ({ title, value, icon, color }) => (
  <Box
    sx={{
      p: 2,
      borderRadius: 2,
      bgcolor: alpha(color, 0.1),
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    }}
  >
    <Box
      sx={{
        width: 40,
        height: 40,
        borderRadius: '50%',
        bgcolor: alpha(color, 0.2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {icon}
    </Box>
    <Box>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600 }}>
        {value}
      </Typography>
    </Box>
  </Box>
);

const DealMetrics = ({ deal }) => {
  const theme = useTheme();
  const opportunityScore = calculateOpportunityScore(deal);
  const riskScore = calculateRiskScore(deal);
  
  return (
    <Card elevation={0} sx={{ bgcolor: 'background.default', height: '100%' }}>
      <CardHeader 
        title="Deal Metrics"
        titleTypography={{ variant: 'h6', fontWeight: 600 }}
        avatar={
          <AssessmentIcon color="primary" />
        }
        sx={{ pb: 1 }}
      />
      <CardContent>
        <Stack spacing={2}>
          <MetricBox
            title="Deal Velocity"
            value={deal.dealVelocity ? `${deal.dealVelocity.toFixed(1)} days` : 'Not enough data'}
            icon={<TrendingUpIcon sx={{ color: theme.palette.primary.main }} />}
            color={theme.palette.primary.main}
          />
          <MetricBox
            title="Opportunity Score"
            value={`${opportunityScore.toFixed(0)}%`}
            icon={<TimelineIcon sx={{ color: theme.palette.success.main }} />}
            color={theme.palette.success.main}
          />
          <MetricBox
            title="Risk Score"
            value={`${riskScore.toFixed(0)}%`}
            icon={<TimelineIcon sx={{ color: theme.palette.error.main }} />}
            color={theme.palette.error.main}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

const ActivityTimeline = ({ deal }) => {
  const theme = useTheme();
  const activities = [
    deal.lastactivitydate && {
      description: 'Last Activity',
      date: deal.lastactivitydate,
      type: 'activity'
    },
    deal.createdate && {
      description: 'Deal Created',
      date: deal.createdate,
      type: 'creation'
    },
    deal.num_contacted_notes > 0 && {
      description: `${deal.num_contacted_notes} Contact Notes`,
      date: deal.lastactivitydate || deal.createdate,
      type: 'notes'
    }
  ].filter(Boolean);

  return (
    <Card elevation={0} sx={{ bgcolor: 'background.default', height: '100%' }}>
      <CardHeader 
        title="Recent Activity"
        titleTypography={{ variant: 'h6', fontWeight: 600 }}
        avatar={
          <TimelineIcon color="primary" />
        }
        sx={{ pb: 1 }}
      />
      <CardContent>
        <List sx={{ 
          '& .MuiListItem-root': { 
            px: 2,
            py: 1.5,
            '&:hover': {
              bgcolor: alpha(theme.palette.primary.main, 0.05),
              borderRadius: 1,
            }
          }
        }}>
          {activities.length > 0 ? (
            activities.map((activity, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemIcon sx={{ 
                    minWidth: '32px',
                    color: theme.palette.primary.main 
                  }}>
                    <CircleIcon sx={{ fontSize: 8, mt: 1.5 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={activity.description}
                    secondary={formatDate(activity.date)}
                    primaryTypographyProps={{ 
                      variant: 'body2',
                      fontWeight: 500,
                      color: 'text.primary'
                    }}
                    secondaryTypographyProps={{ 
                      variant: 'caption',
                      color: 'text.secondary'
                    }}
                  />
                </ListItem>
                {index < (activities.length - 1) && (
                  <Divider variant="inset" component="li" sx={{ ml: 4 }} />
                )}
              </React.Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText 
                primary="No recent activities"
                primaryTypographyProps={{ 
                  variant: 'body2',
                  color: 'text.secondary',
                  sx: { fontStyle: 'italic' }
                }}
              />
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

const AIInsights = ({ deal }) => {
  const theme = useTheme();
  const insights = [];
  
  // Add probability-based insight
  if (deal.truepipeProbability !== undefined) {
    insights.push({
      title: 'Win Probability',
      description: `${(deal.truepipeProbability * 100).toFixed(0)}% chance of closing based on deal characteristics`
    });
  }

  // Add engagement-based insight
  if (deal.num_contacted_notes !== undefined) {
    const engagementLevel = deal.num_contacted_notes >= 5 ? 'High' : 
                          deal.num_contacted_notes >= 3 ? 'Medium' : 'Low';
    insights.push({
      title: 'Engagement Level',
      description: `${engagementLevel} engagement with ${deal.num_contacted_notes} contact notes`
    });
  }

  // Add age-based insight
  if (deal.daysOpen !== undefined) {
    insights.push({
      title: 'Deal Age',
      description: `Deal has been open for ${deal.daysOpen} days`
    });
  }

  return (
    <Card elevation={0} sx={{ bgcolor: 'background.default', height: '100%' }}>
      <CardHeader 
        title="AI Insights"
        titleTypography={{ variant: 'h6', fontWeight: 600 }}
        avatar={
          <LightbulbIcon color="primary" />
        }
        sx={{ pb: 1 }}
      />
      <CardContent>
        <List sx={{
          '& .MuiListItem-root': {
            px: 2,
            py: 1.5,
            '&:hover': {
              bgcolor: alpha(theme.palette.primary.main, 0.05),
              borderRadius: 1,
            }
          }
        }}>
          {insights.length > 0 ? (
            insights.map((insight, index) => (
              <ListItem key={index}>
                <ListItemText 
                  primary={insight.title}
                  secondary={insight.description}
                  primaryTypographyProps={{ 
                    variant: 'subtitle2',
                    fontWeight: 600,
                    color: 'primary.main',
                    gutterBottom: true
                  }}
                  secondaryTypographyProps={{ 
                    variant: 'body2',
                    color: 'text.secondary'
                  }}
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText 
                primary="No insights available"
                primaryTypographyProps={{ 
                  variant: 'body2',
                  color: 'text.secondary',
                  sx: { fontStyle: 'italic' }
                }}
              />
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

const DetailedRow = ({ deal, onToggleWatchlist }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => onToggleWatchlist(deal.id)}>
            {deal.isWatchlisted ? <StarIcon color="primary" /> : <StarBorderIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">{deal.dealname}</Typography>
          <Typography variant="caption" color="textSecondary">
            {deal.company}
          </Typography>
        </TableCell>
        {!isMobile && (
          <TableCell align="right">{formatAmount(deal.amount)}</TableCell>
        )}
        {!isMobile && (
          <TableCell>{formatDate(deal.closedate)}</TableCell>
        )}
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={deal.truepipeProbability * 100}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: theme.palette.grey[200],
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 4,
                    backgroundColor: getProgressColor(deal.truepipeProbability, theme),
                  },
                }}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="textSecondary">
                {`${(deal.truepipeProbability * 100).toFixed(0)}%`}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ 
              margin: 3,
              bgcolor: alpha(theme.palette.primary.main, 0.03),
              borderRadius: 2,
              p: 2
            }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <DealMetrics deal={deal} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ActivityTimeline deal={deal} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <AIInsights deal={deal} />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const WatchlistDetailed = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { aiWatchlist, loading, error, toggleWatchlistItem } = useOverview();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const currentDeals = activeTab === 0 ? aiWatchlist.bestDeals : aiWatchlist.criticalDeals;

  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <LinearProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Watchlist</Typography>
      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label="AI's Best Bets" />
        <Tab label="AI is Concerned" />
      </Tabs>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell width={50}></TableCell>
              <TableCell>Deal Name</TableCell>
              {!isMobile && <TableCell align="right">Amount</TableCell>}
              {!isMobile && <TableCell>Close Date</TableCell>}
              <TableCell>Probability</TableCell>
              <TableCell width={50}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentDeals.map((deal) => (
              <DetailedRow
                key={deal.id}
                deal={deal}
                onToggleWatchlist={toggleWatchlistItem}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default WatchlistDetailed; 