import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import SalesLayout from './SalesLayout';
import SignupModal from '../../components/common/SignupModal';
import Topbar from '../../components/global/Topbar';
import SalesOverview from './overview';

const PublicSalesLayout = () => {
  const { currentUser } = useAuth();
  const [showSignupModal, setShowSignupModal] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      setShowSignupModal(true);
    }
  }, [currentUser]);

  if (currentUser) {
    return <SalesLayout />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Topbar />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="xl" sx={{ mt: 3, mb: 2 }}>
          {/* Public version of the overview content */}
          <SalesOverview isPublic={true} />
        </Container>
      </Box>
      {showSignupModal && (
        <SignupModal 
          open={showSignupModal} 
          onClose={() => setShowSignupModal(false)}
        />
      )}
    </Box>
  );
};

export default PublicSalesLayout; 