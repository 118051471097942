import React from 'react';
import { Box, Typography, Button, Modal, useTheme, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ReactComponent as TpProDarkLogo } from '../home/assets/tp_pro.svg';
import { ReactComponent as TpProLightLogo } from '../home/assets/tp_pro_lightmode.svg';
import { useChartStyles } from '../hooks/useChartStyles';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { useAuth } from '../../contexts/AuthContext';

const SubscribePopup = ({ open, onClose, onNotYet, onNotifyMe }) => {
  const theme = useTheme();
  const { getChartColor } = useChartStyles();
  const { isPro } = useSubscription();
  const { updateUserNotifyPro } = useAuth();

  const proFeatures = [
    'Advanced AI features',
    'Unlimited refreshes with HubSpot',
    'Custom branding and white labeling',
    'Advanced forecasting algorithms',
    'Train own LLM on CRM data',
    'Watch up to 20 deals',
    'Create and save custom dashboards',
  ];

  const Logo = theme.palette.mode === 'dark' ? TpProDarkLogo : TpProLightLogo;

  const handleClose = () => {
    onClose();
  };

  const handleNotYet = () => {
    onClose();
  };

  const handleNotifyMe = async () => {
    try {
      await updateUserNotifyPro(true);
      onNotifyMe(); // Call the new onNotifyMe prop
    } catch (error) {
      console.error('Error updating notify pro preference:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="subscribe-popup-title"
      aria-describedby="subscribe-popup-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 450 },
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: theme.shadows[24],
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box sx={{ width: '180px', height: '90px', mb: 1 }}>
          <Logo width="100%" height="100%" />
        </Box>

        <Typography 
          variant="body1" 
          sx={{ 
            mb: 2, 
            textAlign: 'center',
            color: theme.palette.text.secondary 
          }}
        >
          Unlock the full potential of your sales dashboard with these Pro features:
        </Typography>

        <List sx={{ 
          width: '100%', 
          mb: 3,
          flexGrow: 1, 
          overflowY: 'auto',
          '& .MuiListItem-root': {
            py: 1 
          }
        }}>
          {proFeatures.map((feature, index) => (
            <ListItem key={index} dense>
              <ListItemIcon>
                <CheckCircleRoundedIcon 
                  color="secondary" 
                  sx={{ fontSize: 20 }} 
                />
              </ListItemIcon>
              <ListItemText 
                primary={feature}
                primaryTypographyProps={{
                  fontSize: '0.95rem'
                }} 
              />
            </ListItem>
          ))}
        </List>

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          width: '100%',
          gap: 2 
        }}>
          <Button
            variant="outlined"
            onClick={onNotYet}
            sx={{
              flex: 1,
              py: 1.5,
              color: theme.palette.text.primary,
              borderColor: theme.palette.divider,
              '&:hover': {
                borderColor: theme.palette.text.primary,
              },
            }}
          >
            Not Yet
          </Button>
          <Button
            variant="contained"
            onClick={handleNotifyMe}
            sx={{
              flex: 1,
              py: 1.5,
              bgcolor: getChartColor(0),
              '&:hover': {
                bgcolor: getChartColor(1),
              },
            }}
          >
            Notify Me
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SubscribePopup;