import React, { useState } from 'react';
import { Box, Container, Typography, useTheme, IconButton, Snackbar } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import AppAppBar from '../home/AppAppBar';
import Footer from '../global/Footer';
import MainHero from '../home/assets/MainHero.png';


const SocialShareButton = ({ Icon, platform }) => {
    const [open, setOpen] = useState(false);
    const url = encodeURIComponent("https://truepipe.ai/blog");
    const title = encodeURIComponent("Introducing truepipe: Revolutionizing Data Integration for the AI Era");

    const shareUrls = {
      LinkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      Twitter: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
      Facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      Instagram: `https://www.instagram.com/` // Note: Instagram doesn't have a direct share URL, so we'll just open Instagram
    };

    const handleShare = () => {
      if (shareUrls[platform]) {
        window.open(shareUrls[platform], '_blank');
      } else {
        // Fallback to copying URL
        navigator.clipboard.writeText(decodeURIComponent(url));
        setOpen(true);
      }
    };

    return (
      <>
        <IconButton onClick={handleShare} aria-label={`Share on ${platform}`}>
          <Icon color="action" />
        </IconButton>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          autoHideDuration={2000}
          onClose={() => setOpen(false)}
          message="Link copied to clipboard!"
        />
      </>
    );
  };

const BlogPage = () => {
    const theme = useTheme();

    return (
      <>
        <Container maxWidth="md">
          <AppAppBar />
          <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh', pt: 8, pb: 8 }}>
            {/* New Blog Entry */}
            <Box sx={{ mb: 6, textAlign: 'center' }}>
              <Typography variant="overline" sx={{ color: theme.palette.secondary.main, fontSize: '1.5rem', fontWeight: '500' }}>
                Blog
              </Typography>
              <Typography variant="h1" component="h1" sx={{ 
                color: theme.palette.mode === 'dark' ? '#ffffff' : '#111111', 
                fontWeight: 'bold',
                fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
                lineHeight: 1.2,
                mb: 2
              }}>
                Introducing Free AI Modules: Healthcheck & Forecast
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="body1" sx={{ mr: 2 }}>
                  by the truepipe team
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="text.secondary">
                  December 3, 2024
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 4 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <SocialShareButton Icon={LinkedInIcon} platform="LinkedIn" />
                <SocialShareButton Icon={TwitterIcon} platform="Twitter" />
                <SocialShareButton Icon={FacebookIcon} platform="Facebook" />
                <SocialShareButton Icon={InstagramIcon} platform="Instagram" />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                  We're excited to announce that we're making two of our most powerful AI modules available for free: <a href="/healthcheck" style={{ color: theme.palette.secondary.main }}>AI Healthcheck</a> and <a href="/forecast" style={{ color: theme.palette.secondary.main }}>AI Forecast</a>. These modules represent the cutting edge of AI-powered data analytics, and we're thrilled to make them accessible to everyone.
                </Typography>

                <Typography variant="h4" sx={{ mt: 6, mb: 3, color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                  AI Healthcheck: Your Data Quality Guardian
                </Typography>

                <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                  The AI Healthcheck module provides real-time analysis of your data pipeline health, automatically identifying potential issues before they impact your business. It monitors data quality, completeness, and consistency while providing actionable recommendations for improvement. With intelligent anomaly detection and trend analysis, you'll always stay ahead of data quality challenges.
                </Typography>

                <Typography variant="h4" sx={{ mt: 6, mb: 3, color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                  AI Forecast: Predictive Intelligence at Your Fingertips
                </Typography>

                <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                  Our AI Forecast module leverages advanced machine learning algorithms to provide accurate predictions and insights from your data. Whether you're looking to forecast sales trends, predict resource needs, or anticipate market changes, AI Forecast delivers reliable predictions with detailed confidence intervals and contributing factor analysis.
                </Typography>

                <Typography variant="h4" sx={{ mt: 6, mb: 3, color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                  Limited Time Offer: Full Platform Access
                </Typography>

                <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                  For a limited time, we're offering complete access to the entire truepipe platform at no cost. This includes all our premium features, integrations, and AI capabilities. Visit <a href="https://truepipe.ai/sales" style={{ color: theme.palette.secondary.main }}>truepipe.ai/sales</a> to sign up and start exploring the full potential of AI-powered data integration.
                </Typography>

                <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                  These modules are just the beginning of our commitment to democratizing access to advanced AI tools. We believe that by making these capabilities freely available, we can help organizations of all sizes harness the power of AI to transform their data operations.
                </Typography>

                <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2, color: theme.palette.text.primary }}>
                  Ready to experience the future of data integration?
                </Typography>

                <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                  Sign up now at <a href="https://truepipe.ai/sales" style={{ color: theme.palette.secondary.main }}>truepipe.ai/sales</a>
                </Typography>
              </Box>
            </Box>

            {/* Divider between blog posts */}
            <Box sx={{ my: 8, borderTop: `1px solid ${theme.palette.divider}` }} />

            {/* Original Blog Entry */}
            <Box sx={{ mb: 6, textAlign: 'center' }}>
              <Typography variant="h1" component="h2" sx={{ 
                color: theme.palette.mode === 'dark' ? '#ffffff' : '#111111', 
                fontWeight: 'bold',
                fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
                lineHeight: 1.2,
                mb: 2
              }}>
                Introducing truepipe: Data Integration for the AI Era
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="body1" sx={{ mr: 2 }}>
                  by cofounders Tyler & Tim
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" color="text.secondary">
                  September 6, 2024
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mb: 4, textAlign: 'center' }}>
            <img
              src={MainHero}
              alt="Featured blog post"
              style={{
                width: '100%',
                maxWidth: '800px',
                height: 'auto',
                borderRadius: '20px',
                objectFit: 'cover',
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <SocialShareButton Icon={LinkedInIcon} platform="LinkedIn" />
              <SocialShareButton Icon={TwitterIcon} platform="Twitter" />
              <SocialShareButton Icon={FacebookIcon} platform="Facebook" />
              <SocialShareButton Icon={InstagramIcon} platform="Instagram" />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                Today we're introducing truepipe, the first AI-native data integration platform. truepipe takes the best of both worlds from AI-powered data tools and traditional ETL platforms. The result is a new tool built for the way people want to manage data today, with both their team and AI in one place. Every time there's a major paradigm shift, a new data integration tool emerges. In the era of AI, truepipe is that tool.
              </Typography>

              <Typography variant="h4" sx={{ mt: 6, mb: 3, color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                AI Has Entered the Data Pipeline
              </Typography>

              <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                Traditional ETL tools have proven their worth, but they're often rigid and separate from the AI workflows that modern businesses rely on. truepipe allows users to create data pipelines with AI assistance and then invite team members to collaborate, or add AI to existing data workflows. The AI functions like a virtual data engineer, with all of the context from previous transformations, data sources, and app integrations. This means it can provide unique insights not available elsewhere, such as which data sources are most relevant for a specific analysis, how data quality impacts downstream processes, or an intelligent overview of how a new data source might affect existing pipelines.
              </Typography>

              <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                Teams can choose their preferred AI model or let truepipe select the best model for each task. truepipe is model-agnostic and is currently powered by state-of-the-art language models, with more options coming soon. Integrations with popular data sources, data warehouses, and analytics tools are available now, with additional connectors being added regularly to our growing ecosystem.
              </Typography>

              <Typography variant="h4" sx={{ mt: 6, mb: 3, color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                Breaking Free from Data Silos
              </Typography>

              <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                Unlike traditional data integration platforms, the atomic unit of work in truepipe is a pipeline rather than a fixed workflow. truepipe pipelines can be shared with multiple teams or departments – and others can be added later as needed – so data doesn't get stuck in silos. This subtle but fundamental change means less friction because users don't need to recreate pipelines for every new use case. Like modern data workflows, truepipe pipelines are flexible and have AI-generated documentation for easy understanding and maintenance.
              </Typography>

              <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.primary }}>
                We're excited to embark on this journey and invite you to join us in revolutionizing the way businesses handle data integration. Visit <a href="https://truepipe.ai" style={{ color: theme.palette.secondary.main }}>truepipe.ai</a> to learn more and sign up for early access.
              </Typography>

              <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2, color: theme.palette.text.primary }}>
                Let's build the future of data integration together.
              </Typography>

              <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                The truepipe Team
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default BlogPage;