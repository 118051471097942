import React from 'react';
import { Box, Grid, Typography, LinearProgress, Alert } from '@mui/material';
import { 
  CheckCircle,
  Warning,
  Error,
  Email,
  People,
  Assessment,
  ContentCopy
} from '@mui/icons-material';
import { useHealthCheck } from '../../../contexts/HealthcheckContextProvider';
import { useTheme } from '@mui/material/styles';
import { melon, turquoise, mint, periwinkle } from '../../../theme';

const MetricCard = ({ title, value, subtitle, icon: Icon, status = 'neutral' }) => {
  const theme = useTheme();
  
  const getStatusColor = () => {
    switch (status) {
      case 'good':
        return theme.palette.mode === 'dark' ? turquoise : mint;
      case 'warning':
        return melon;
      case 'bad':
        return theme.palette.error.main;
      default:
        return periwinkle;
    }
  };
  
  return (
    <Box sx={{
      p: 2,
      borderRadius: 1,
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' 
          ? theme.palette.background.light
          : theme.palette.grey[50],
      },
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
        <Icon sx={{ color: getStatusColor() }} />
        <Typography 
          variant="subtitle2" 
          color="text.secondary"
        >
          {title}
        </Typography>
      </Box>
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 0.5,
          color: getStatusColor(),
          fontWeight: 'bold'
        }}
      >
        {value}
      </Typography>
      {subtitle && (
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ 
            opacity: 0.8,
            fontSize: '0.875rem'
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

const HealthMetricsSummary = () => {
  const { metrics, isLoading, error } = useHealthCheck();
  const theme = useTheme();

  if (error) {
    return (
      <Alert 
        severity="error"
        sx={{ 
          borderRadius: theme.shape.borderRadius,
          mb: 2 
        }}
      >
        Error loading metrics: {error.message}
      </Alert>
    );
  }

  if (isLoading || !metrics) {
    return (
      <Box sx={{ 
        p: 3, 
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
      }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      p: 3,
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
    }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ 
          color: theme.palette.text.primary,
          mb: 3,
          fontWeight: 'medium'
        }}
      >
        Health Metrics Overview
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <MetricCard
            title="Overall Data Quality"
            value={`${metrics.dataHealth.deals.percentComplete}%`}
            subtitle="Based on critical fields"
            icon={Assessment}
            status={metrics.dataHealth.deals.percentComplete > 90 ? 'good' : 
                   metrics.dataHealth.deals.percentComplete > 70 ? 'warning' : 'bad'}
          />
        </Grid>
        
        <Grid item xs={12} md={3}>
          <MetricCard
            title="Total Records"
            value={metrics.accountAge.totalDeals + metrics.accountAge.totalContacts}
            subtitle={`${metrics.accountAge.totalDeals} Deals, ${metrics.accountAge.totalContacts} Contacts`}
            icon={People}
            status="neutral"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <MetricCard
            title="Email Deliverability"
            value={`${metrics.emailDeliverability.rate}%`}
            subtitle={`${metrics.emailDeliverability.bounceRate}% bounce rate`}
            icon={Email}
            status={metrics.emailDeliverability.rate > 95 ? 'good' : 
                   metrics.emailDeliverability.rate > 85 ? 'warning' : 'bad'}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <MetricCard
            title="Duplicate Rate"
            value={`${metrics.duplicateRate.toFixed(1)}%`}
            subtitle="Contact records"
            icon={ContentCopy}
            status={metrics.duplicateRate < 1 ? 'good' : 
                   metrics.duplicateRate < 5 ? 'warning' : 'bad'}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HealthMetricsSummary; 