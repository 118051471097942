// src/components/sales/HealthcheckDashboard/OwnerPipelineTable.jsx

import React, { useMemo } from 'react';
import { useHealthCheck } from '../../../contexts/HealthcheckContextProvider';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Chip,
  LinearProgress,
  Tooltip,
  Box,
  useTheme,
  Collapse,
  IconButton,
} from '@mui/material';
import {
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  Speed as SpeedIcon,
  AttachMoney as MoneyIcon,
  Assessment as AssessmentIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { turquoise, mint, melon, tangerine, bittersweet, lightRed } from '../../../theme';
import { formatCurrency, formatPercentage } from '../../../components/utils/formatters';

const OwnerPipelineTable = () => {
  const { ownerMetrics, isLoading } = useHealthCheck();
  const [orderBy, setOrderBy] = React.useState('healthScore');
  const [order, setOrder] = React.useState('desc');
  const theme = useTheme();

  // Get owners from metrics
  const owners = ownerMetrics || [];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (isLoading) {
    return (
      <Paper style={{ padding: '20px', backgroundColor: theme.palette.background.paper }}>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Owner Pipeline Health
        </Typography>
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      </Paper>
    );
  }

  if (!owners?.length) {
    return (
      <Paper style={{ padding: '20px', backgroundColor: theme.palette.background.paper }}>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Owner Pipeline Health
        </Typography>
        <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
          No owner data available.
        </Typography>
      </Paper>
    );
  }

  const sortedData = [...owners].sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return a[orderBy] > b[orderBy] ? -1 : 1;
  });

  const getColorForScore = (score) => {
    const isDarkMode = theme.palette.mode === 'dark';
    if (score > 70) return isDarkMode ? turquoise : mint;
    if (score > 40) return isDarkMode ? melon : tangerine;
    return isDarkMode ? bittersweet : lightRed;
  };

  const getChipColor = (value, thresholds) => {
    const isDarkMode = theme.palette.mode === 'dark';
    if (value > thresholds.high) return isDarkMode ? turquoise : mint;
    if (value > thresholds.medium) return isDarkMode ? melon : tangerine;
    return isDarkMode ? bittersweet : lightRed;
  };

  return (
    <Paper style={{ padding: '20px', backgroundColor: theme.palette.background.paper }}>
      <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Owner Pipeline Health
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'owner'}
                  direction={order}
                  onClick={() => handleRequestSort('owner')}
                >
                  Owner
                </TableSortLabel>
              </TableCell>
              <TableCell>Deals & Value</TableCell>
              <TableCell>Health Indicators</TableCell>
              <TableCell>Performance Metrics</TableCell>
              <TableCell>Risk Assessment</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'healthScore'}
                  direction={order}
                  onClick={() => handleRequestSort('healthScore')}
                >
                  Overall Health
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row) => (
              <Row
                key={row.id}
                row={row}
                getColorForScore={getColorForScore}
                getChipColor={getChipColor}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const Row = ({ row, getColorForScore, getChipColor }) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const getScaledColor = (value, isRisk = false) => {
    if (isRisk) {
      // For risk: low is good (green), high is bad (red)
      if (value < 10) return isDarkMode ? '#00897B' : '#B2DFDB';  // Minimal
      if (value < 20) return isDarkMode ? '#26A69A' : '#80CBC4';  // Very Low
      if (value < 30) return isDarkMode ? '#4DB6AC' : '#4DB6AC';  // Low
      if (value < 40) return isDarkMode ? '#80CBC4' : '#26A69A';  // Low-Moderate
      if (value < 50) return isDarkMode ? melon : '#FFB74D';      // Moderate
      if (value < 60) return isDarkMode ? '#FFB74D' : tangerine;  // Moderate-High
      if (value < 70) return isDarkMode ? '#FF8A65' : '#FF7043';  // High
      if (value < 80) return isDarkMode ? '#FF7043' : '#FF5722';  // Very High
      if (value < 90) return isDarkMode ? '#F4511E' : '#E64A19';  // Severe
      return isDarkMode ? bittersweet : lightRed;                  // Critical
    } else {
      // For engagement: high is good (green), low is bad (red)
      if (value > 90) return isDarkMode ? '#00897B' : '#B2DFDB';  // Outstanding
      if (value > 80) return isDarkMode ? '#26A69A' : '#80CBC4';  // Excellent
      if (value > 70) return isDarkMode ? '#4DB6AC' : '#4DB6AC';  // Very Good
      if (value > 60) return isDarkMode ? '#80CBC4' : '#26A69A';  // Good
      if (value > 50) return isDarkMode ? melon : '#FFB74D';      // Moderate
      if (value > 40) return isDarkMode ? '#FFB74D' : tangerine;  // Fair
      if (value > 30) return isDarkMode ? '#FF8A65' : '#FF7043';  // Poor
      if (value > 20) return isDarkMode ? '#FF7043' : '#FF5722';  // Very Poor
      if (value > 10) return isDarkMode ? '#F4511E' : '#E64A19';  // Critical
      return isDarkMode ? bittersweet : lightRed;                  // Severe
    }
  };

  // Updated helper functions for more granular labels
  const getEngagementLabel = (value) => {
    if (value > 90) return 'Outstanding';
    if (value > 80) return 'Excellent';
    if (value > 70) return 'Very Good';
    if (value > 60) return 'Good';
    if (value > 50) return 'Moderate';
    if (value > 40) return 'Fair';
    if (value > 30) return 'Poor';
    if (value > 20) return 'Very Poor';
    if (value > 10) return 'Critical';
    return 'Severe';
  };

  const getRiskLabel = (value) => {
    if (value < 10) return 'Minimal';
    if (value < 20) return 'Very Low';
    if (value < 30) return 'Low';
    if (value < 40) return 'Low-Moderate';
    if (value < 50) return 'Moderate';
    if (value < 60) return 'Moderate-High';
    if (value < 70) return 'High';
    if (value < 80) return 'Very High';
    if (value < 90) return 'Severe';
    return 'Critical';
  };

  return (
    <React.Fragment>
      <TableRow 
        hover 
        sx={{
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          cursor: 'pointer'
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.owner}</TableCell>
        <TableCell>
          <Box>
            <Typography variant="body2">
              {row.totalDeals} deals
              {row.dealsClosingSoon > 0 && (
                <Tooltip title={`${row.dealsClosingSoon} deals closing soon`}>
                  <Chip
                    icon={<ScheduleIcon />}
                    label={row.dealsClosingSoon}
                    size="small"
                    sx={{
                      ml: 1,
                      backgroundColor: isDarkMode ? melon : tangerine,
                      color: theme.palette.getContrastText(isDarkMode ? melon : tangerine),
                    }}
                  />
                </Tooltip>
              )}
            </Typography>
            <Typography variant="body2">
              {formatCurrency(row.totalValue)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Tooltip title={`${row.outdatedDeals} outdated deals`}>
                <Chip
                  label={`${row.outdatedDeals} outdated`}
                  size="small"
                  icon={row.outdatedDeals > 0 ? <WarningIcon /> : <CheckCircleIcon />}
                  sx={{
                    mr: 1,
                    backgroundColor: row.outdatedDeals > 0 
                      ? isDarkMode ? bittersweet : lightRed 
                      : isDarkMode ? turquoise : mint,
                    color: theme.palette.getContrastText(
                      row.outdatedDeals > 0 
                        ? isDarkMode ? bittersweet : lightRed 
                        : isDarkMode ? turquoise : mint
                    ),
                  }}
                />
              </Tooltip>
              {row.needsAttentionDeals > 0 && (
                <Tooltip title={`${row.needsAttentionDeals} deals need attention`}>
                  <Chip
                    label={`${row.needsAttentionDeals} attention`}
                    size="small"
                    icon={<WarningIcon />}
                    sx={{
                      backgroundColor: isDarkMode ? melon : tangerine,
                      color: theme.palette.getContrastText(isDarkMode ? melon : tangerine),
                    }}
                  />
                </Tooltip>
              )}
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                Age: {row.avgDealAge ? Math.round(row.avgDealAge) : 'N/A'} days
                {row.isAgeTrending && (
                  <Tooltip title="Deals are aging">
                    <TrendingUpIcon
                      sx={{ ml: 1, fontSize: 16, color: isDarkMode ? bittersweet : lightRed }}
                    />
                  </Tooltip>
                )}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                Velocity: {row.dealVelocity ? Math.round(row.dealVelocity) : 'N/A'} days
                {row.isVelocityTrending && (
                  <Tooltip title="Deal velocity is decreasing">
                    <TrendingDownIcon
                      sx={{ ml: 1, fontSize: 16, color: isDarkMode ? bittersweet : lightRed }}
                    />
                  </Tooltip>
                )}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Typography variant="body2">
              Win Prob: {formatPercentage(row.avgWinProbability)}
              {row.avgWinProbability < 0.3 && (
                <Tooltip title="Low win probability">
                  <TrendingDownIcon
                    sx={{ ml: 1, fontSize: 16, color: isDarkMode ? bittersweet : lightRed }}
                  />
                </Tooltip>
              )}
            </Typography>
            <Typography variant="body2">
              <Tooltip title="Deal Velocity">
                <SpeedIcon
                  sx={{ mr: 1, fontSize: 16, color: isDarkMode ? turquoise : mint }}
                />
              </Tooltip>
              {(row.dealVelocity || 0).toFixed(1)} days
            </Typography>
            <Typography variant="body2">
              <Tooltip title="Forecast Accuracy">
                <AssessmentIcon
                  sx={{ mr: 1, fontSize: 16, color: isDarkMode ? turquoise : mint }}
                />
              </Tooltip>
              {formatPercentage(row.forecastAccuracy)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <Tooltip title={`Engagement Score: ${getEngagementLabel(row.engagementScore || 0)}`}>
              <Chip
                label={`Engagement: ${
                  row.engagementScore !== undefined ? row.engagementScore.toFixed(0) : 'N/A'
                }%`}
                size="small"
                sx={{
                  mb: 1,
                  backgroundColor: getScaledColor(row.engagementScore || 0, false),
                  color: theme.palette.getContrastText(getScaledColor(row.engagementScore || 0, false))
                }}
              />
            </Tooltip>
            <Typography variant="body2">
              <Tooltip title={`Risk Score: ${getRiskLabel(row.riskScore || 0)}`}>
                <Chip
                  label={`Risk: ${(row.riskScore || 0).toFixed(0)}%`}
                  size="small"
                  sx={{
                    mt: 1,
                    backgroundColor: getScaledColor(row.riskScore || 0, true),
                    color: theme.palette.getContrastText(getScaledColor(row.riskScore || 0, true))
                  }}
                />
              </Tooltip>
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={row.healthScore}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor:
                    theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 5,
                    backgroundColor: getColorForScore(row.healthScore),
                  },
                }}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">
                {`${Math.round(row.healthScore)}%`}
              </Typography>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell 
          colSpan={7} 
          sx={{ 
            paddingBottom: 0, 
            paddingTop: 0,
            backgroundColor: theme.palette.background.default
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ExpandedDetails row={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const ExpandedDetails = ({ row }) => {
  const metrics = [
    { label: 'Deals Created This Quarter', value: row.dealsCreatedThisQuarter },
    { label: 'Deals Closing This Quarter', value: row.dealsClosingThisQuarter },
    { label: 'Needs Attention Deals', value: row.needsAttentionDeals },
    { label: 'Avg. Number of Associated Contacts', value: row.avgNumAssociatedContacts, format: 'number' },
    { label: 'Avg. Number of Notes', value: row.avgNotesPerDeal, format: 'number' },
    { label: 'Avg. Lead Conversion Rate', value: row.avgLeadConversionRate, format: 'percentage' },
    { label: 'Total Forecast Amount', value: row.totalForecastAmount, format: 'currency' },
    { label: 'Total Projected Amount', value: row.totalProjectedAmount, format: 'currency' },
    { label: 'Total MRR', value: row.totalMRR, format: 'currency' },
    { label: 'Total ARR', value: row.totalARR, format: 'currency' },
    { label: 'Avg. Time Since Last Contacted', value: row.avgTimeSinceLastContacted, format: 'days' },
    { label: 'Deals with No Next Activity', value: row.dealsWithNoNextActivity },
    { label: 'Avg. Time in Deal Stage', value: row.avgTimeInDealStage, format: 'days' },
    { label: 'Outdated Deals', value: row.outdatedDeals },
    { label: 'Deals Needing Attention', value: row.needsAttentionDeals },
    { label: 'Deals Closing Soon', value: row.dealsClosingSoon },
    { label: 'Average Deal Age', value: row.avgDealAge, format: 'days' },
    { label: 'Deal Velocity', value: row.dealVelocity, format: 'days' },
    { label: 'Forecast Accuracy', value: row.forecastAccuracy, format: 'percentage' },
  ];

  return (
    <Box margin={1}>
      <Typography variant="h6" gutterBottom component="div" sx={{ fontWeight: 500 }}>
        Additional Metrics
      </Typography>
      <Table size="small" aria-label="details">
        <TableBody>
          {metrics.map((metric, index) => (
            <MetricRow key={index} {...metric} />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const MetricRow = ({ label, value, format = 'number' }) => {
  const theme = useTheme();
  
  const formattedValue = useMemo(() => {
    if (value === undefined || value === null) return 'N/A';
    
    switch (format) {
      case 'currency':
        return formatCurrency(value);
      case 'percentage':
        return formatPercentage(value);
      case 'days':
        return `${(value || 0).toFixed(1)} days`;
      default:
        return value.toLocaleString();
    }
  }, [value, format]);

  return (
    <TableRow hover>
      <TableCell 
        sx={{ 
          color: theme.palette.text.secondary,
          fontWeight: 500
        }}
      >
        {label}
      </TableCell>
      <TableCell>{formattedValue}</TableCell>
    </TableRow>
  );
};

export default OwnerPipelineTable;